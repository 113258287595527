import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IProject } from 'interfaces/Project.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IGetProjectItemArgs,
  IProjectCollectionArgs,
  IProjectCollectionResponse,
  IProjectDeleteArgs,
  IProjectPostArgs,
  IProjectPutArgs,
} from './projects.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getProjects = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IProjectCollectionArgs,
  BaseQueryFn,
  string,
  IProjectCollectionResponse
> =>
  build.query<IProjectCollectionResponse, IProjectCollectionArgs>({
    providesTags: ['Project'],
    query: (queryArg: IProjectCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/projects${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IProject>
    ): IProjectCollectionResponse =>
      transformCollectionResponse<IProject>(response),
  });

export const getProjectItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetProjectItemArgs, BaseQueryFn, string, IProject> =>
  build.query<IProject, IGetProjectItemArgs>({
    providesTags: ['Project'],
    query: (queryArg: IGetProjectItemArgs) => ({
      url: `api/projects/${queryArg.id}`,
    }),
  });

export const postProjectItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IProjectPostArgs, BaseQueryFn, string, IProject> =>
  build.mutation<IProject, IProjectPostArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Project'],
    query: (queryArg) => ({
      url: `api/projects`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putProjectItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IProjectPutArgs, BaseQueryFn, string, IProject> =>
  build.mutation<IProject, IProjectPutArgs>({
    invalidatesTags: ['Project'],
    query: (queryArg) => ({
      url: `api/projects/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteProjectItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IProjectDeleteArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IProjectDeleteArgs>({
    invalidatesTags: ['Project'],
    query: (queryArg) => ({
      url: `api/projects/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
