import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  device,
  Grid2,
  Icons,
  OverviewCardCounter,
  OverviewCardWrapper,
  Theme,
  Utils,
} from 'team-hero-ui';

import type { IMissionOverviewTabProps } from './MissionOverviewTab.types';
import useGetClientLoginDashboardWidget from 'components/ClientLoginDashboard/useGetClientLoginDashboardWidget.hook';
import MissionOverviewCard from './MissionOverviewCard';
import {
  BriefingTitleStyled,
  MissionOverviewTabStyled,
  MissionTasksStyled,
  OverviewDetailsStyled,
} from './MissionOverviewTab.styled';
import ClientLoginConversationPanel from 'components/ClientLoginDashboard/ClientLoginConversationPanel';
import FeatureFlag from 'components/FeatureFlag/FeatureFlag';
import ReformattedMissionHtml from 'components/ReformattedMissionHtml';

const { useMediaQuery } = Utils;

const MissionOverviewTab: FC<IMissionOverviewTabProps> = ({ mission }) => {
  const { t } = useTranslation('general');
  const isMdScreenSize = useMediaQuery(device.md);

  const {
    applicationsPendingByMissionId,
    timetrackingsPendingByMissionId,
    ratingsPendingByMissionId,
  } = useGetClientLoginDashboardWidget({
    skip: !mission,
  });

  const timetrackingsPendingCount = timetrackingsPendingByMissionId(mission.id);
  const ratingsPendingCount = ratingsPendingByMissionId(mission.id);
  const applicationsPendingCount = applicationsPendingByMissionId(mission.id);

  return (
    <MissionOverviewTabStyled>
      <Grid2 container>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <OverviewDetailsStyled>
            <MissionOverviewCard mission={mission} />
            <MissionTasksStyled>
              <OverviewCardWrapper>
                <OverviewCardCounter
                  title={t('clientLogin.dashboard.tasks.timetrackings')}
                  count={timetrackingsPendingCount}
                  right={-25}
                  bottom={-20}
                  counterColor={
                    timetrackingsPendingCount > 0
                      ? Theme.colors.primary.red
                      : Theme.colors.primary.green
                  }
                  icon={
                    <Icons.TimeTrackingCheckIcon
                      svgColor={Theme.colors.greyShades.grey5}
                      svgSize={100}
                    />
                  }
                />
              </OverviewCardWrapper>
              <FeatureFlag flag={'clientDashboardRatingsCard'}>
                <OverviewCardWrapper>
                  <OverviewCardCounter
                    title={t('clientLogin.dashboard.tasks.ratings')}
                    count={ratingsPendingCount}
                    right={-25}
                    bottom={-15}
                    counterColor={
                      ratingsPendingCount > 0
                        ? Theme.colors.primary.red
                        : Theme.colors.primary.green
                    }
                    icon={
                      <Icons.StarIcon
                        svgColor={Theme.colors.greyShades.grey5}
                        svgSize={100}
                      />
                    }
                  />
                </OverviewCardWrapper>
              </FeatureFlag>
              <FeatureFlag flag={'clientDashboardApplicationsCard'}>
                <OverviewCardWrapper>
                  <OverviewCardCounter
                    title={t('clientLogin.dashboard.tasks.applications')}
                    count={applicationsPendingCount}
                    right={-30}
                    bottom={-15}
                    counterColor={
                      applicationsPendingCount > 0
                        ? Theme.colors.primary.red
                        : Theme.colors.primary.green
                    }
                    icon={
                      <Icons.PollOK
                        svgColor={Theme.colors.greyShades.grey5}
                        svgSize={100}
                      />
                    }
                  />
                </OverviewCardWrapper>
              </FeatureFlag>
            </MissionTasksStyled>
            {mission.description && (
              <Card
                title={
                  <BriefingTitleStyled>
                    <Icons.BriefingIcon svgSize={24} /> {t('details.briefing')}
                  </BriefingTitleStyled>
                }
              >
                <ReformattedMissionHtml html={mission.description} />
              </Card>
            )}
          </OverviewDetailsStyled>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <ClientLoginConversationPanel
            conversationType='missionLeader'
            mission={mission}
            leader={mission.leader}
            conversationsFilter={[
              { key: 'relatedMission', operator: 'AND', value: mission.id },
            ]}
            height={isMdScreenSize ? 'calc(100dvh - 108px)' : undefined}
          />
        </Grid2>
      </Grid2>
    </MissionOverviewTabStyled>
  );
};

export default MissionOverviewTab;
