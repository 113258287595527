import { FC, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { Button, ConversationListItem, htmlToString } from 'team-hero-ui';

import type { IConversationItemProps } from './ConversationItem.interface';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { getProjectAndMissionTitle } from 'pages/Messages/Messages.helper';
import { ConversationItemWrapperStyled } from './ConversationItem.styled';
import { ParticipantMinCellRenderer } from 'components/cellRenderers/ParticipantMinCellRenderer';

const today = moment();

const ConversationItem: FC<IConversationItemProps> = ({
  conversation,
  conversationIdQueryParam,
  currentUserContact,
}) => {
  const { dateFormat, yesterdayDayOnlyFormat, lastWeekDayOnlyFormat } =
    useDateFormat();

  const isSentMessage =
    conversation.lastMessage?.fromContact?.id === currentUserContact?.id;

  const { missionTitle, projectTitle } =
    getProjectAndMissionTitle(conversation);

  const getCreatedAtDate = useCallback(
    (createdAt?: string) => {
      if (!createdAt) {
        return null;
      }

      return moment(createdAt).isSame(today, 'day')
        ? moment(createdAt).fromNow(true)
        : moment(createdAt).calendar({
            sameElse: dateFormat,
            lastDay: yesterdayDayOnlyFormat,
            lastWeek: lastWeekDayOnlyFormat,
          });
    },
    [dateFormat, yesterdayDayOnlyFormat, lastWeekDayOnlyFormat]
  );

  return (
    <ConversationItemWrapperStyled>
      <Button
        color='cardWhite'
        component={Link}
        componentProps={{
          to: `/messages/${conversation.id}/details`,
        }}
      >
        <ConversationListItem
          isRead={conversation.totalUnreadMessages === 0}
          avatar={<ParticipantMinCellRenderer conversation={conversation} />}
          message={
            htmlToString(conversation.lastMessage?.subject || '') ||
            htmlToString(conversation.lastMessage?.content || '') ||
            '-'
          }
          isSelected={conversationIdQueryParam === conversation.id.toString()}
          isSentMessage={isSentMessage}
          createdAt={getCreatedAtDate(conversation.lastMessage?.createdAt)}
          name={
            conversation.isGroupChat
              ? conversation.title
              : conversation.lastMessage?.fromContact?.firstName
          }
          title={
            missionTitle && projectTitle
              ? `${missionTitle} ${projectTitle}`
              : missionTitle || projectTitle
          }
        />
      </Button>
    </ConversationItemWrapperStyled>
  );
};

export default ConversationItem;
