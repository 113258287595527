import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type { IInterview } from 'interfaces/Interview.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IGetInterviewCollectionArgs,
  IGetInterviewCollectionResponse,
  IGetInterviewItemArgs,
  IInterviewPostArgs,
  IInterviewPutArgs,
} from './interviews.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getInterviews = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetInterviewCollectionArgs,
  BaseQueryFn,
  string,
  IGetInterviewCollectionResponse
> =>
  build.query<IGetInterviewCollectionResponse, IGetInterviewCollectionArgs>({
    providesTags: ['Interview'],
    query: (queryArg: IGetInterviewCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `api/application_interviews${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IInterview>
    ): IGetInterviewCollectionResponse =>
      transformCollectionResponse<IInterview>(response),
  });

export const getInterviewItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetInterviewItemArgs, BaseQueryFn, string, IInterview> =>
  build.query<IInterview, IGetInterviewItemArgs>({
    providesTags: ['Interview'],
    query: (queryArg: IGetInterviewItemArgs) => ({
      url: `api/application_interviews/${queryArg.id}`,
    }),
  });

export const postInterviewItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IInterviewPostArgs, BaseQueryFn, string, IInterview> =>
  build.mutation<IInterview, IInterviewPostArgs>({
    invalidatesTags: ['Interview'],
    query: (queryArg) => ({
      url: 'api/application_interviews',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putInterviewItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IInterviewPutArgs, BaseQueryFn, string, IInterview> =>
  build.mutation<IInterview, IInterviewPutArgs>({
    invalidatesTags: ['Interview', 'ApplicationInterviewInvite'],
    query: (queryArg) => ({
      url: `api/application_interviews/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
