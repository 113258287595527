import { isPlainObject } from '@reduxjs/toolkit';

import type {
  IPaginationArgs,
  TSortDirection,
} from 'interfaces/Pagination.interface';
import type { IPagination } from 'hooks/usePagination.hook';
import type { ISorting } from 'hooks/useSort.hook';
import type { ISimpleFilter } from 'hooks/useFilters.hook';

export const createPaginationApiArgs = (
  pagination: IPagination | false,
  sorting?: ISorting,
  overridePage?: number
): IPaginationArgs => {
  const orderParams: { [key: string]: TSortDirection } = {};
  if (sorting && sorting.columnKey && sorting.direction) {
    const orderKey = `order[${sorting.columnKey}]`;
    orderParams[orderKey] = sorting.direction;
  }

  if (pagination === false) {
    return {
      pagination: false,
      order: orderParams,
    };
  }

  return {
    page: overridePage || pagination.currentPage,
    itemsPerPage: pagination.itemsPerPage,
    order: orderParams,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stripUndefined = (obj: any) => {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, never> = { ...obj };

  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === 'undefined') delete copy[k];
  }
  return copy;
};

export const serializeFlatObject = (
  paramObject: Record<string, string | boolean | number | undefined>,
  divider: '&' | '?' = '&'
): string => {
  const query = new URLSearchParams(stripUndefined(paramObject));
  return divider + query;
};

const checkValueIsBoolean = (value: unknown): value is boolean => {
  return typeof value === 'boolean';
};

const checkValueIsNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

const checkValueIsString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const serializeFilters = (
  filters?: ISimpleFilter[],
  divider: '&' | '?' = '&'
): string => {
  if (!filters || filters.length === 0) {
    return '';
  }
  let query = '';
  filters.forEach((filter, index) => {
    if (index === 0) {
      query += divider;
    } else {
      query += '&';
    }
    query += filter.key;
    switch (filter.operator) {
      case 'NOT':
        query += '!=';
        break;
      case 'OR':
        query += '[]=';
        break;
      case 'AND':
      default:
        query += '=';
    }
    if (
      checkValueIsString(filter.value) ||
      checkValueIsBoolean(filter.value) ||
      checkValueIsNumber(filter.value)
    ) {
      query += encodeURIComponent(filter.value);
    } else {
      query += filter.value ? encodeURIComponent(filter.value) : null;
    }
  });
  return query;
};
