import { ApiService } from 'services/api/api.service';
import { TThumbnailSize } from 'helpers/file/file.helpers.types';

interface ICreateFileUrlParams {
  apiRequestUrl: string;
  thumbnailSize?: TThumbnailSize;
}

const createFileUrl = async ({
  apiRequestUrl,
  thumbnailSize,
}: ICreateFileUrlParams): Promise<string> => {
  // add thumbnail size to url as Get request param
  if (thumbnailSize) {
    apiRequestUrl += `?thumbnail=${thumbnailSize}`;
  }

  try {
    const response = await ApiService.Get<ArrayBuffer>(apiRequestUrl, {
      responseType: 'arraybuffer',
    });

    return window.URL.createObjectURL(new Blob([response as ArrayBuffer]));
  } catch (_e) {
    return '';
  }
};

const createBlobFile = (apiRequestUrl: string): Promise<Blob> => {
  return ApiService.Get<Blob>(apiRequestUrl, {
    responseType: 'blob',
  }).then((response) => {
    return response as unknown as Blob;
  });
};

export default createFileUrl;
export { createBlobFile };
