import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type { ICategory } from 'interfaces/Category.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IGetCategoryCollectionArgs,
  IGetCategoryCollectionResponse,
} from './categories.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getCategories = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetCategoryCollectionArgs,
  BaseQueryFn,
  string,
  IGetCategoryCollectionResponse
> =>
  build.query<IGetCategoryCollectionResponse, IGetCategoryCollectionArgs>({
    providesTags: ['Category'],
    query: (queryArg: IGetCategoryCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/global_categories${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<ICategory>
    ): IGetCategoryCollectionResponse =>
      transformCollectionResponse<ICategory>(response),
  });
