import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IRating } from 'interfaces/Rating.interface';
import type { IMissionRatingSummaryRow } from '../MissionRatingsTab.types';
import { ratingsSummaryColumnsFactory } from '../components/RatingsSummaryColumns.config';
import { SummaryRow } from 'components/Table/components/SummaryRow/SummaryRow';

interface IUseTimetrackingsEntrySummaryRowProps {
  ratings: IRating[];
  enableClientContacts: boolean;
}

interface IUseTimetracingsSummaryRowReturn {
  summaryBottomRow: ReactElement | null;
}

export const useRatingsSummaryRow = ({
  ratings,
  enableClientContacts,
}: IUseTimetrackingsEntrySummaryRowProps): IUseTimetracingsSummaryRowReturn => {
  const { t } = useTranslation('general');

  const row: IMissionRatingSummaryRow = useMemo(
    () => ({
      averageValue:
        ratings.reduce((acc, curr) => acc + curr.averageValue, 0) /
        ratings.length,
    }),
    [ratings]
  );

  const summaryBottomRow = useMemo(() => {
    if (!ratings.length) {
      return null;
    }

    return (
      <>
        <SummaryRow
          columns={ratingsSummaryColumnsFactory({
            t,
            enableClientContacts,
          })}
          row={row}
        />
      </>
    );
  }, [ratings.length, t, enableClientContacts, row]);

  return { summaryBottomRow };
};
