import {
  EndpointBuilder,
  QueryDefinition,
  BaseQueryFn,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IGetMissionCollectionArgs,
  IGetMissionCollectionResponse,
  IGetMissionArgs,
  IPostMissionArgs,
} from 'services/projectMissions/projectMissions.api.types';

const defaultTotalCount = 0;
const defaultItems: IProjectMission[] = [];

export const getProjectMissions = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMissionCollectionArgs,
  BaseQueryFn,
  string,
  IGetMissionCollectionResponse
> =>
  build.query<IGetMissionCollectionResponse, IGetMissionCollectionArgs>({
    providesTags: ['ProjectMission'],
    query: (queryArg: IGetMissionCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `/api/project_missions${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IProjectMission>
    ): IGetMissionCollectionResponse => {
      const totalCount =
        (response['hydra:totalItems'] as number) || defaultTotalCount;
      const items =
        (response['hydra:member'] as IProjectMission[]) || defaultItems;
      return { items, totalCount };
    },
  });

export const getProjectMissionItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetMissionArgs, BaseQueryFn, string, IProjectMission> => {
  return build.query<IProjectMission, IGetMissionArgs>({
    providesTags: ['ProjectMission'],
    query: (queryArg: IGetMissionArgs) => ({
      url: `/api/project_missions/${queryArg.id}`,
    }),
  });
};

export const postProjectMissionItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IPostMissionArgs, BaseQueryFn, string, IProjectMission> =>
  build.mutation<IProjectMission, IPostMissionArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ProjectMission'],
    query: (queryArg) => ({
      url: `api/project_missions`,
      method: 'POST',
      body: queryArg.body,
    }),
  });
