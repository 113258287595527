import type { IAbsenceCategory } from 'interfaces/AbsenceCategory.interface';
import type { ICategory } from 'interfaces/Category.interface';
import type {
  ISelectContactValue,
  ISelectValue,
} from 'interfaces/Data/SelectValue.interface';
import type { IJobAd, IJobAdItem } from 'interfaces/JobAd.interface';
import type { IGetJobAdCollectionResponse } from 'services/jobAds/jobAds.api.type';
import type { IGetCategoryCollectionResponse } from 'services/categories/categories.types';
import type { IGetAbsenceCategoriesResponse } from 'services/contactAbsences/contactAbsences.api.types';
import type { TExternalDataValues } from './externalDataValues.types';

const filterEmptyItems = (items: ISelectValue[]) => {
  return items.filter((i) => i.label !== '');
};

type TCollectionResponse =
  | IGetAbsenceCategoriesResponse
  | IGetJobAdCollectionResponse
  | IGetCategoryCollectionResponse;

const mapResultByType = (
  type?: TExternalDataValues,
  collection?: TCollectionResponse
): ISelectValue[] => {
  let mappedItems: ISelectValue[] | ISelectContactValue[] = [];
  if (collection) {
    switch (type) {
      case 'absenceCategory':
        mappedItems = (collection.items as IAbsenceCategory[]).map((item) => ({
          label: item.name || '',
          value: item['@id'],
        }));
        break;
      case 'category':
        mappedItems = (collection.items as ICategory[]).map((item) => ({
          label: item.category || '',
          value: item['@id'],
        }));
        break;
      case 'jobAd':
        mappedItems = (collection.items as IJobAdItem[]).map((item) => ({
          label: item.title || '',
          value: (item as IJobAd)['@id'],
        }));
        break;
      default:
        return [];
    }
  }

  return filterEmptyItems(mappedItems);
};

export default mapResultByType;
