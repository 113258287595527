import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Icons, Theme } from 'team-hero-ui';
import { Moment } from 'moment';

import type { IFilterRendererProps } from 'components/FilterPanel/FilterPanel.type';
import LocalizedDatePickerRange from 'components/LocalizedDatePickerRange';
import {
  FilterElementStyled,
  FilterLabelStyled,
  FilterLeftElementStyled,
} from 'components/FilterPanel/filterRenderers/FilterElement.styled';

const parseDate = (date?: Moment | null) => {
  if (!date) {
    return null;
  }
  return date;
};

export const FromToDateRenderer: FC<IFilterRendererProps> = ({
  label,
  onChange,
  value,
  styleType,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Temporary solution: When DatePickerRange will be fixed please remove */}
      {label && (
        <FilterLeftElementStyled>
          <FilterLabelStyled>{label}</FilterLabelStyled>
        </FilterLeftElementStyled>
      )}
      <FilterElementStyled>
        <LocalizedDatePickerRange
          from={value?.from || null}
          to={value?.to || null}
          onChange={(fromDate, toDate) =>
            onChange({
              from: parseDate(fromDate),
              to: parseDate(toDate),
            })
          }
          placeholderFrom={t('modal.labels.from')}
          placeholderTo={t('modal.labels.to')}
          // TODO: Fix DatePickerRange component to display placeholders just like TextFields do
        />
        <IconButton
          size='small'
          onClick={() => onChange({ from: null, to: null })}
        >
          <Icons.CloseIcon
            svgSize={16}
            svgColor={
              styleType === 'modal'
                ? Theme.colors.primary.white
                : Theme.colors.primary.grey
            }
          />
        </IconButton>
      </FilterElementStyled>
    </>
  );
};
