import { FC, MouseEventHandler, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, Icons, Loader, Theme } from 'team-hero-ui';

import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';
import {
  DownloadButtonStyled,
  DropdownStyled,
} from './DownloadSectionDropdown.styled';

export interface IDownloadSectionDropdownProps {
  handleOnXLSDownload?: () => void;
  handleOnPDFDownload?: MouseEventHandler<HTMLButtonElement> | undefined;
  isLoadingXLSDownload?: boolean;
  isLoadingPDFDownload?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

export const DownloadSectionDropdown: FC<IDownloadSectionDropdownProps> = ({
  handleOnXLSDownload,
  handleOnPDFDownload,
  isLoadingXLSDownload,
  isLoadingPDFDownload,
  disabled,
  children,
}) => {
  const { t } = useTranslation('general');

  return (
    <DropdownStyled>
      <Dropdown
        disabled={disabled}
        styleType='toolbar'
        iconColor={Theme.colors.greyShades.grey2}
        borderColor={Theme.colors.greyShades.grey5}
        label={
          <Icons.DownloadIcon
            svgColor={Theme.colors.greyShades.grey2}
            svgSize={16}
          />
        }
        dataTestId={'download-section-dropdown'}
      >
        {handleOnXLSDownload && (
          <DropdownItem>
            <DownloadButtonStyled
              onClick={(e: MouseEvent) =>
                preventEventWrapper(e, handleOnXLSDownload)
              }
            >
              {t('table.header.export.xls')}{' '}
              {isLoadingXLSDownload && (
                <Loader loaderType='static' loaderSize='tiny' />
              )}
            </DownloadButtonStyled>
          </DropdownItem>
        )}
        {handleOnPDFDownload && (
          <DropdownItem>
            <DownloadButtonStyled onClick={handleOnPDFDownload}>
              {t('table.header.export.pdf')}{' '}
              {isLoadingPDFDownload && (
                <Loader loaderType='static' loaderSize='tiny' />
              )}
            </DownloadButtonStyled>
          </DropdownItem>
        )}
        {children}
      </Dropdown>
    </DropdownStyled>
  );
};
