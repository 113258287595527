import type { TUserRole } from 'interfaces/Role.interface';

type TRequiredUserRolesConfig = {
  [key in
    | 'changeEmail'
    | 'passwordUpdate'
    | 'heroLogin'
    | 'manageTwoFactor'
    | 'application'
    | 'changePassword'
    | 'clientLogin'
    | 'retiredLogin']: TUserRole[];
};

export const requiredUserRolesConfig: TRequiredUserRolesConfig = {
  changeEmail: [
    'ROLE_HERO_LOGIN',
    'ROLE_CLIENT_LOGIN',
    'ROLE_HERO_RETIRED_LOGIN',
  ],
  manageTwoFactor: [
    'ROLE_HERO_LOGIN',
    'ROLE_CLIENT_LOGIN',
    'ROLE_APPLICANT',
    'ROLE_HERO_RETIRED_LOGIN',
  ],
  passwordUpdate: [
    'ROLE_USER_CHANGE_PASSWORD',
    'ROLE_HERO_LOGIN',
    'ROLE_CLIENT_LOGIN',
    'ROLE_HERO_RETIRED_LOGIN',
  ],
  changePassword: [
    'ROLE_HERO_LOGIN',
    'ROLE_CLIENT_LOGIN',
    'ROLE_HERO_RETIRED_LOGIN',
  ],
  heroLogin: ['ROLE_HERO_LOGIN'],
  application: ['ROLE_APPLICANT'],
  clientLogin: ['ROLE_CLIENT_LOGIN'],
  retiredLogin: ['ROLE_HERO_RETIRED_LOGIN'],
};
