import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { TExternalDataValues } from './externalDataValues.types';
import { scalarFieldsOnlyFilter } from 'helpers/filters/filters.helper';

const getFiltersByType = (type?: TExternalDataValues): ISimpleFilter[] => {
  switch (type) {
    case 'category':
    case 'jobAd':
    default:
      return [scalarFieldsOnlyFilter];
  }
};

export default getFiltersByType;
