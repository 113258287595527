import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useNativeAppUpdate from './useNativeAppUpdate.hook';

const NativeAppUpdateProvider: FC = () => {
  const { triggerFetchVersionInfo } = useNativeAppUpdate();
  const { ready } = useTranslation();

  useEffect(() => {
    if (ready) {
      triggerFetchVersionInfo();
    }
  }, [ready, triggerFetchVersionInfo]);
  return null;
};

export default NativeAppUpdateProvider;
