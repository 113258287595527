import { chunk, isBoolean, isNaN, isNumber } from 'lodash';
import Joi from 'joi';
import { TFunction } from 'i18next';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import type { TLocale } from 'interfaces/Locale.interface';
import type {
  ICustomField,
  ICustomFields,
  TCustomFieldRenderType,
  TCustomFieldValues,
} from 'interfaces/CustomFields.interface';
import type {
  IOption,
  ISection,
  ISectionItem,
} from 'components/Modals/AddEditModal/modalConfig/modalConfig.interface';
import type {
  IFormSection,
  TSectionRowType as TJobAdSectionRowType,
} from 'pages/public/JobAdApplyForm/interfaces/FormConfig';
import type {
  IApplicationDocumentFilesData,
  IApplicationFormState,
} from 'pages/public/JobAdApplyForm/JobAdApplyForm.interface';
import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { emptyField } from 'components/Modals/AddEditModal/modalConfig/contactModal/contactModal.config.helpers';
import { numberLengthValidationStrategy } from 'config/validationStrategy/numberLengthValidationStrategy';
import { withdrawalDateValidationStrategy } from 'config/validationStrategy/withdrawalDateValidationStrategy';
import { socialSecurityNumberValidation } from 'config/validationStrategy/socialSecurityNumberValidationStrategy';
import { taxIdentificationNumberValidation } from 'config/validationStrategy/taxIdentificationValidationStrategy';
import { validationFieldRequired } from 'config/validationStrategy/validationFieldRequired';
import { dateValidationStrategy } from 'config/validationStrategy/dateValidationStrategy';
import { ibanValidationStrategy } from 'config/validationStrategy/ibanValidationStrategy';
import { bicValidationStrategy } from 'config/validationStrategy/bicValidationStrategy';

interface ICustomFieldsServiceArgs {
  customFields?: ICustomFields;
  language: TLocale;
}

interface IGetCustomFieldsArgs {
  columnsNumber?: number;
  skipReadOnlyFields?: boolean;
  skipHrFields?: boolean;
  skipNotInHeroLoginFields?: boolean;
  skipExcludedRenderTypes?: boolean;
}

interface ICustomFieldsServiceReturn {
  mapValuesToOptions: (values: TCustomFieldValues) => IOption[];
  getCustomFieldItemsJobAdForm: () => ICustomField[];
  getCustomFields: (args: IGetCustomFieldsArgs) => ISection[];
  mapFieldsToJoiSchema: (
    t: TFunction,
    fileValidationStrategy?: (required: boolean) => Joi.AnySchema<unknown>,
    isJobAdForm?: boolean
  ) => Joi.PartialSchemaMap | undefined;
  getCustomFieldsJobAdForm: () => IFormSection[];
  parseCustomFieldApiValues: (
    values: IApplicationFormState
  ) => Record<string, unknown>;
  parseCustomFieldFileApiValues: (
    values: IApplicationFormState
  ) => IApplicationDocumentFilesData[];
  getCustomFieldsColumns: <T>(
    getDisplayValue: (fieldKey: string, unformattedValue: unknown) => string,
    exportHandler?: (key: string, row: T) => void
  ) => IColumnDefinitionType<T>[];
}

const fallbackLanguage: TLocale = 'en';

const removeWhitespaceFieldKeys = [
  'employmentTaxNumber',
  'bankAccountNumber',
  'bankBusinessIdentifierCode',
  'socialSecurityNumber',
];

const checkIsFileArray = (value: unknown): value is File[] => {
  return Array.isArray(value) && value.every((el) => el instanceof File);
};

export const excludedRenderTypes: TCustomFieldRenderType[] = ['file'];

export const customFieldsService = ({
  customFields,
  language,
}: ICustomFieldsServiceArgs): ICustomFieldsServiceReturn => {
  const contactApplicationFields =
    customFields &&
    (customFields['App\\Entity\\Contact'] ||
      customFields['App\\Entity\\Application']);

  const mapValuesToOptions = (values: TCustomFieldValues): IOption[] => {
    const isArray = Array.isArray(values);

    if (isArray) {
      return values.map((el) => ({
        label: el,
        value: el,
      }));
    } else {
      return values.keys.map((key) => {
        return {
          label:
            values.displayNames[key]?.[language] ||
            values.displayNames[key]?.[fallbackLanguage] ||
            key,
          value: key,
        };
      });
    }
  };

  const getFieldValidationSchema = (
    t: TFunction,
    field: ICustomField,
    fileValidationStrategy?: (required: boolean) => Joi.AnySchema<unknown>,
    isJobAdForm?: boolean
  ): Joi.AnySchema<unknown> => {
    const optionalRule = Joi.optional().label(
      field.displayName[language] ||
        field.displayName[fallbackLanguage] ||
        field.key
    );
    // if validation is for jobAdForm but field is not in application form, do not add rule
    // if validation is for heroLogin but field is not in heroLogin, do not add rule
    if (
      (isJobAdForm && !field.inApplicationForm) ||
      (!isJobAdForm && !field.inHeroLogin)
    ) {
      return optionalRule;
    }

    if (field.key === 'healthInsuranceCompanyNumber') {
      const requiredLength = 8;
      return numberLengthValidationStrategy(requiredLength, field.required, t);
    }

    if (field.key === 'withdrawalDate') {
      return withdrawalDateValidationStrategy(field.required, t);
    }

    if (field.renderType === 'file' && fileValidationStrategy) {
      return fileValidationStrategy(field.required);
    }

    if (field.key === 'socialSecurityNumber') {
      return socialSecurityNumberValidation(field.required, t);
    }

    if (field.key === 'taxIdentificationNumber' || field.key === 'taxId') {
      return taxIdentificationNumberValidation(field.required, t);
    }

    if (field.key === 'bankAccountNumber') {
      return ibanValidationStrategy(field.required, t);
    }

    if (field.key === 'bankBusinessIdentifierCode') {
      return bicValidationStrategy(field.required, t);
    }

    // special validation for number fields
    if (field.type === 'integer' || field.type === 'float') {
      // field is not required
      if (!field.required) {
        return Joi.number()
          .allow('', null)
          .label(
            field.displayName[language] ||
              field.displayName[fallbackLanguage] ||
              field.key
          );
      } else {
        // field is required
        return Joi.number()
          .required()
          .label(
            field.displayName[language] ||
              field.displayName[fallbackLanguage] ||
              field.key
          )
          .messages(validationFieldRequired(t));
      }
    }

    if (!field.required) {
      return optionalRule;
    }

    // validation, if field is supposed to be a string
    if (field.type === 'string' || field.type === 'text') {
      return Joi.string()
        .required()
        .label(
          field.displayName[language] ||
            field.displayName[fallbackLanguage] ||
            field.key
        )
        .messages(validationFieldRequired(t));
    }

    if (field.type === 'datetime' || field.type === 'date') {
      return dateValidationStrategy(t).label(
        field.displayName[language] ||
          field.displayName[fallbackLanguage] ||
          field.key
      );
    }

    // validation, if field is required but neither number nor string
    return Joi.any()
      .required()
      .label(
        field.displayName[language] ||
          field.displayName[fallbackLanguage] ||
          field.key
      )
      .messages(validationFieldRequired(t));
  };

  const mapFieldsToJoiSchema = (
    t: TFunction,
    fileValidationStrategy?: (required: boolean) => Joi.AnySchema<unknown>,
    isJobAdForm = false
  ): Joi.PartialSchemaMap | undefined => {
    if (!contactApplicationFields) return;

    return contactApplicationFields.fields.reduce((acc, field) => {
      const fieldValidationSchema = getFieldValidationSchema(
        t,
        field,
        fileValidationStrategy,
        isJobAdForm
      );

      return {
        ...acc,
        [field.key]: fieldValidationSchema,
      };
    }, {});
  };

  const getCustomFields = ({
    columnsNumber = 2,
    skipReadOnlyFields = false,
    skipHrFields = false,
    skipNotInHeroLoginFields = false,
    skipExcludedRenderTypes = false,
  }: IGetCustomFieldsArgs): ISection[] => {
    const selectedFields = contactApplicationFields;

    if (!selectedFields) return [];

    return [...selectedFields.groups]
      .sort((a, b) => a.order - b.order)
      .map((group) => {
        const fields = selectedFields.fields.filter(
          (el) =>
            el.group === group.name &&
            (skipReadOnlyFields ? !el.readOnly : true) &&
            (skipHrFields ? !el.hrField : true) &&
            (skipNotInHeroLoginFields
              ? el.inHeroLogin || (!el.inHeroLogin && el.readOnly)
              : true) &&
            (skipExcludedRenderTypes
              ? !excludedRenderTypes.includes(el.renderType)
              : true)
        );

        // split fields into n columns
        const columns = chunk(
          fields,
          Math.ceil(fields.length / columnsNumber)
        ).map((columnFields) => {
          const sectionItems: ISectionItem[][] = columnFields.map((field) => {
            return [
              {
                propertyName: field.key,
                renderType: field.renderType,
                label: field.displayName[language],
                required: field.required,
                isReadOnly: field.readOnly,
                isHrField: field.hrField,
                inScore: field.inScore,
                hint: field.hint ? field.hint[language] : '',
                isInHeroLogin: field.inHeroLogin,
                customFieldRenderer: true,
                removeWhitespace: removeWhitespaceFieldKeys.includes(field.key),
                options: field.values
                  ? mapValuesToOptions(field.values)
                  : undefined,
              },
            ];
          });
          return {
            key: uuidv4(),
            items: sectionItems,
          };
        });

        return {
          key: group.name,
          title: group.displayName[language],
          columns: columns.length === 1 ? [...columns, emptyField] : columns,
        };
      })
      .filter((el) => el.columns.length > 0);
  };

  const getCustomFieldsJobAdForm = (): IFormSection[] => {
    const publicCustomFields =
      customFields && customFields['App\\Entity\\Application'];

    if (!publicCustomFields) return [];

    return [...publicCustomFields.groups]
      .sort((a, b) => a.order - b.order)
      .map((group) => {
        const fields = publicCustomFields.fields.filter(
          (el) => el.group === group.name
        );

        const rowFields = fields.map((field) => {
          return {
            propertyName: field.key,
            renderType: field.renderType as TJobAdSectionRowType,
            label:
              field.displayName[language] ||
              field.displayName[fallbackLanguage] ||
              '',
            required: field.required,
            hint: field.hint?.[language] || field.hint[fallbackLanguage] || '',
            options: field.values
              ? mapValuesToOptions(field.values)
              : undefined,
          };
        });

        return {
          title:
            group.displayName[language] ||
            group.displayName[fallbackLanguage] ||
            '',
          rows: [
            {
              key: uuidv4(),
              items: rowFields,
              title:
                group.displayName[language] ||
                group.displayName[fallbackLanguage] ||
                '',
            },
          ],
        };
      });
  };

  const getCustomFieldItemsJobAdForm = (): ICustomField[] => {
    const publicCustomFields =
      customFields && customFields['App\\Entity\\Application'];
    return publicCustomFields?.fields || [];
  };

  const parseCustomFieldApiValues = (
    values: IApplicationFormState
  ): Record<string, unknown> => {
    // reformat values bases on custom field render type
    const customFieldItems = getCustomFieldItemsJobAdForm();
    return Object.keys(values).reduce<Record<string, unknown>>((acc, key) => {
      const customField = customFieldItems.find((el) => el.key === key);
      if (!customField) return acc;

      const value = values[key];

      // remove file fields from values
      if (customField.renderType === 'file') {
        return acc;
      }

      if (customField.renderType === 'date') {
        return {
          ...acc,
          [key]: value ? moment(value).format('YYYY-MM-DD') : null,
        };
      }

      if (customField.renderType === 'time') {
        return {
          ...acc,
          [key]: value ? moment(value).format('YYYY-MM-DD HH:mm') : null,
        };
      }

      if (customField.renderType === 'number') {
        return {
          ...acc,
          [key]: isNumber(value) && !isNaN(value) ? Number(value) : null,
        };
      }

      if (customField.renderType === 'text') {
        return {
          ...acc,
          [key]: value || null,
        };
      }

      if (customField.renderType === 'switch') {
        // return boolean value or null
        return {
          ...acc,
          [key]: isBoolean(value) ? value : null,
        };
      }

      return {
        ...acc,
        [key]: value,
      };
    }, {});
  };

  const parseCustomFieldFileApiValues = (
    values: IApplicationFormState
  ): IApplicationDocumentFilesData[] => {
    const customFieldItems = getCustomFieldItemsJobAdForm();
    return Object.keys(values).reduce<IApplicationDocumentFilesData[]>(
      (acc, key) => {
        const customField = customFieldItems.find((el) => el.key === key);
        if (!customField) return acc;
        const value = values[key];
        if (
          customField.renderType === 'file' &&
          checkIsFileArray(value) &&
          customField.template
        ) {
          const filesToAdd = value.map((file) => ({
            file,
            template: customField.template || '',
          }));
          return [...acc, ...filesToAdd];
        }
        return acc;
      },
      []
    );
  };

  const getCustomFieldsColumns = <T>(
    getDisplayValue?: (fieldKey: string, unformattedValue: unknown) => string,
    exportHandler?: (key: string, row: T) => void
  ): IColumnDefinitionType<T>[] => {
    const selectedFields = contactApplicationFields;
    if (!selectedFields) return [];

    const result = selectedFields.fields
      .filter((field) => {
        return !excludedRenderTypes.includes(field.renderType);
      })
      .map((field) => {
        const { displayName, inExport, key } = field;
        return {
          key: field.key,
          label: displayName[language],
          displayInMenu: false,
          visibility: false,
          cellType: 'custom',
          displayInExport: inExport,
          isCustomField: true,
          cellExportHandler: (row) => {
            if (exportHandler) {
              return exportHandler(key, row);
            } else if (getDisplayValue) {
              return getDisplayValue(key, row[key as keyof T]);
            } else {
              return row[key as keyof T];
            }
          },
        } as IColumnDefinitionType<T>;
      });
    return result;
  };

  return {
    getCustomFields,
    getCustomFieldItemsJobAdForm,
    getCustomFieldsJobAdForm,
    mapValuesToOptions,
    mapFieldsToJoiSchema,
    parseCustomFieldApiValues,
    parseCustomFieldFileApiValues,
    getCustomFieldsColumns,
  };
};
