import {
  BaseQueryFn,
  MutationDefinition,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IGetMessageConversationsArgs,
  IGetMessageConversationsResponse,
  IPostMessageConversationArgs,
  IPutMessageConversationArgs,
  IPutMessageConversationReadUnreadArgs,
} from './messageConversations.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type { IMessageConversation } from 'interfaces/MessageConversation.interface';

export const getMessageConversations = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMessageConversationsArgs,
  BaseQueryFn,
  string,
  IGetMessageConversationsResponse
> =>
  build.query<IGetMessageConversationsResponse, IGetMessageConversationsArgs>({
    providesTags: ['MessageConversation'],
    query: (queryArg: IGetMessageConversationsArgs | undefined) => {
      const paginationParamObject = {
        participant: queryArg?.participant,
        page: queryArg?.page,
        itemsPerPage: queryArg?.itemsPerPage,
        pagination: queryArg?.pagination,
        ...queryArg?.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg?.filters);
      return {
        url: `/api/message_conversations${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IMessageConversation>
    ): IGetMessageConversationsResponse =>
      transformCollectionResponse<IMessageConversation>(response),
  });

export const getMessageConversation = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<number, BaseQueryFn, string, IMessageConversation> =>
  build.query<IMessageConversation, number>({
    providesTags: ['MessageConversation'],
    query: (id) => {
      return {
        url: `/api/message_conversations/${id}`,
      };
    },
  });

export const postMessageConversation = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostMessageConversationArgs,
  BaseQueryFn,
  string,
  IMessageConversation
> =>
  build.mutation<IMessageConversation, IPostMessageConversationArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['MessageConversation'],
    query: (queryArg) => ({
      url: '/api/message_conversations',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putMessageConversation = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutMessageConversationArgs,
  BaseQueryFn,
  string,
  IMessageConversation
> =>
  build.mutation<IMessageConversation, IPutMessageConversationArgs>({
    invalidatesTags: ['MessageConversation'],
    query: (queryArg) => ({
      url: `/api/message_conversations/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const putMessageConversationReadUnread = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutMessageConversationReadUnreadArgs,
  BaseQueryFn,
  string,
  IMessageConversation
> =>
  build.mutation<IMessageConversation, IPutMessageConversationReadUnreadArgs>({
    invalidatesTags: ['MessageConversation'],
    query: (queryArg) => ({
      url: `/api/message_conversations/${queryArg.id}/status`,
      method: 'PUT',
      body: {
        updatedStatus: queryArg.body.status,
      },
    }),
  });

export const deleteMessageConversation = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<number, BaseQueryFn, string, void> =>
  build.mutation<void, number>({
    invalidatesTags: ['MessageConversation'],
    query: (id) => ({
      url: `/api/message_conversations/${id}`,
      method: 'DELETE',
    }),
  });
