import {
  BaseQueryFn,
  EndpointBuilder,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type {
  IPostTwoFactorAddChannelArgs,
  IPostTwoFactorDeactivateChannelArgs,
  IPostTwoFactorSetDefaultChannelArgs,
  IPostTwoFactorTriggerCodeArgs,
  IPostTwoFactorTriggerCodeResponse,
  IPostTwoFactorValidateArgs,
  IPutUserTwoFactorActivationArgs,
} from 'services/twoFactorAuth/twoFactorAuth.types';
import type { ITokenResponse } from 'services/user/user.types';

export const postTwoFactorTriggerCode = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostTwoFactorTriggerCodeArgs,
  BaseQueryFn,
  string,
  IPostTwoFactorTriggerCodeResponse
> =>
  build.mutation<
    IPostTwoFactorTriggerCodeResponse,
    IPostTwoFactorTriggerCodeArgs
  >({
    query: (queryArg) => ({
      url: `api/2fa/send`,
      method: 'POST',
      body: queryArg.triggerCodeBody,
    }),
  });

export const postTwoFactorValidate = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostTwoFactorValidateArgs,
  BaseQueryFn,
  string,
  ITokenResponse
> =>
  build.mutation<ITokenResponse, IPostTwoFactorValidateArgs>({
    query: (queryArg) => ({
      url: `api/2fa/validate`,
      method: 'POST',
      body: queryArg.validateCredentials,
    }),
  });

export const postTwoFactorDeactivateChannel = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostTwoFactorDeactivateChannelArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IPostTwoFactorDeactivateChannelArgs>({
    invalidatesTags: ['User', 'UserMe'],
    query: (queryArg) => ({
      url: `api/2fa/channel/deactivate`,
      method: 'POST',
      body: queryArg.channelBody,
    }),
  });

export const postTwoFactorSetDefaultChannel = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostTwoFactorSetDefaultChannelArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IPostTwoFactorSetDefaultChannelArgs>({
    invalidatesTags: ['User', 'UserMe'],
    query: (queryArg) => ({
      url: `api/2fa/channel/default`,
      method: 'POST',
      body: queryArg.channelBody,
    }),
  });

export const postTwoFactorAddChannel = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostTwoFactorAddChannelArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IPostTwoFactorAddChannelArgs>({
    invalidatesTags: ['User', 'UserMe'],
    query: (queryArg) => ({
      url: `api/2fa/channel/add`,
      method: 'POST',
      body: queryArg.channelBody,
    }),
  });

export const putTwoFactorUserActivate = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutUserTwoFactorActivationArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IPutUserTwoFactorActivationArgs>({
    invalidatesTags: ['User', 'UserMe'],
    query: (queryArg) => ({
      url: `api/2fa/user/${queryArg.id}/activate`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const putTwoFactorUserDeactivate = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutUserTwoFactorActivationArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IPutUserTwoFactorActivationArgs>({
    invalidatesTags: ['User', 'UserMe'],
    query: (queryArg) => ({
      url: `api/2fa/user/${queryArg.id}/deactivate`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
