import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from 'team-hero-ui';

import { ActionButtonStyled } from '../BulkEditRow.styled';
import { bulkEditIconSize } from '../constants';

interface ITimetrackingBulkEditProps {
  selectedRowsIds: number[];
  onBulkClientApproval: (ids: number[]) => void;
}

const TimetrackingBulkEdit: FC<ITimetrackingBulkEditProps> = ({
  selectedRowsIds,
  onBulkClientApproval,
}) => {
  const { t } = useTranslation();

  return (
    <ActionButtonStyled
      color='transparentBlack'
      onClick={() => onBulkClientApproval(selectedRowsIds)}
      startIcon={
        <Icons.TimeTrackingSignedV1ExIcon svgSize={bulkEditIconSize} />
      }
    >
      {t('table.action.timesheetsApprove')}
    </ActionButtonStyled>
  );
};

export default TimetrackingBulkEdit;
