import { Theme } from 'team-hero-ui';
import { css } from 'styled-components';

const shadowDark = css`
  box-shadow: 0 0 4px 2px ${Theme.colors.greyShades.grey1};
`;
const shadowLight = css`
  box-shadow: 0 0 4px 2px ${Theme.colors.greyShades.grey5};
`;
const shadowLight1 = css`
  box-shadow: 0 0 6px 2px ${Theme.colors.greyShades.grey4};
`;

const shadowLight2 = css`
  box-shadow: 0 -1px 2px 0 ${Theme.colors.greyShades.grey5};
`;

const shadowTransparent = css`
  box-shadow: 0 0 4px 2px ${Theme.colors.others.shadow.boxShadow1};
`;

export {
  shadowDark,
  shadowLight,
  shadowLight1,
  shadowLight2,
  shadowTransparent,
};
