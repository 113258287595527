import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, Theme } from 'team-hero-ui';
import { Link } from 'react-router';

import {
  LoginMessageStyled,
  ChatIconStyled,
  RetireCardStyled,
  RetireTitleStyled,
  AddButtonStyled,
  BottomSectionStyled,
  RetireTextStyled,
  LoginMessageTextStyled,
  RetiredLabelStyled,
} from './RetireCard.styled';

const RetireCard: FC = () => {
  const { t } = useTranslation('general');

  return (
    <RetireCardStyled>
      <RetireTitleStyled>
        <ChatIconStyled>
          <Icons.SuccessIcon
            svgColor={Theme.colors.primary.green}
            svgSize={150}
          />
        </ChatIconStyled>
        <RetireTextStyled>{t('dashboard.greeting.text')}</RetireTextStyled>
      </RetireTitleStyled>
      <LoginMessageStyled>
        <LoginMessageTextStyled>
          {t('dashboard.retireMode.explanation')}
        </LoginMessageTextStyled>
        <BottomSectionStyled>
          <AddButtonStyled
            startIcon={<Icons.PlusIconV1 svgSize={20} />}
            color='borderGrey'
            component={Link}
            componentProps={{ to: '/messages/new-message' }}
          >
            {t('action.contactEmployer')}
          </AddButtonStyled>
          <RetiredLabelStyled>
            {t('dashboard.retireMode.chip')}
          </RetiredLabelStyled>
        </BottomSectionStyled>
      </LoginMessageStyled>
    </RetireCardStyled>
  );
};

export default RetireCard;
