import { useCallback } from 'react';
import { Capacitor } from '@capacitor/core';
import { saveAs } from 'file-saver';
import { Directory, Filesystem } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import { Share } from '@capacitor/share';

interface IDownloadFileReturn {
  triggerBlobDownload: (blob: Blob, fileName: string) => Promise<void>;
  triggerFileUrlDownload: (url: string, fileName: string) => Promise<void>;
}

const useDownloadFile = (): IDownloadFileReturn => {
  const triggerNativeBlobDownload = useCallback(
    async (blob: Blob, fileName: string) => {
      // save blob to filesystem
      await write_blob({
        path: `/${fileName}`,
        directory: Directory.Documents,
        blob: blob,
      });
      // get the fileUri of the file
      const uriResult = await Filesystem.getUri({
        path: fileName,
        directory: Directory.Documents,
      });
      try {
        // open native share dialog
        await Share.share({
          title: fileName,
          dialogTitle: fileName,
          files: [uriResult.uri],
        });
      } catch (_e) {
        // if share is rejected, do nothing
      }
    },
    []
  );

  const triggerBlobDownload = useCallback(
    async (blob: Blob, fileName: string) => {
      if (Capacitor.isNativePlatform()) {
        await triggerNativeBlobDownload(blob, fileName);
      } else {
        saveAs(blob, fileName);
      }
    },
    [triggerNativeBlobDownload]
  );

  const triggerFileUrlDownload = useCallback(
    async (url: string, fileName: string) => {
      if (Capacitor.isNativePlatform()) {
        // url to blob
        const blob = await fetch(url).then((response) => response.blob());

        await triggerNativeBlobDownload(blob, fileName);
      } else {
        saveAs(url, fileName);
      }
    },
    [triggerNativeBlobDownload]
  );

  return {
    triggerBlobDownload,
    triggerFileUrlDownload,
  };
};

export default useDownloadFile;
