import {
  IFilterDatePickerData,
  IFilterDateRangePickerData,
  IFilterDefinition,
  IFilterInputData,
  IFilterMultiSelectData,
  IFilterRangePickerData,
  IFilterSelectData,
  IFilterSwitchData,
  TFilterType,
} from 'interfaces/Table/DataTableFilters.interface';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IFilterInitialValue } from './useInitialFilterValues.hook';

const getFromToNumberData = (
  key: string,
  filterType: TFilterType,
  rangePickerData?: IFilterRangePickerData,
  alreadySetFiltersByKey?: ISimpleFilter[]
): IFilterInitialValue | null => {
  if (
    alreadySetFiltersByKey?.length &&
    alreadySetFiltersByKey[0].value &&
    rangePickerData?.keyFrom &&
    rangePickerData?.keyTo
  ) {
    const fromFilter = alreadySetFiltersByKey.find((filter) =>
      filter.key.includes(rangePickerData?.keyFrom)
    );
    const toFilter = alreadySetFiltersByKey.find((filter) =>
      filter.key.includes(rangePickerData?.keyTo)
    );
    return {
      key,
      filterType,
      value: {
        from: fromFilter?.value ? Number(fromFilter.value) : null,
        to: toFilter?.value ? Number(toFilter.value) : null,
      },
    };
  }
  return null;
};

const getDateRangePickerData = (
  key: string,
  filterType: TFilterType,
  dateRangePickerData?: IFilterDateRangePickerData,
  alreadySetFiltersByKey?: ISimpleFilter[],
  useInitialValues = false
): IFilterInitialValue | null => {
  // if there is already a filter set for this key, load this one
  if (alreadySetFiltersByKey?.length) {
    const startFilter = alreadySetFiltersByKey.find((filter) =>
      filter.key.includes('after')
    );
    const endFilter = alreadySetFiltersByKey.find((filter) =>
      filter.key.includes('before')
    );

    if (endFilter || startFilter) {
      return {
        key,
        filterType,
        value: {
          from: startFilter?.value
            ? new Date(startFilter.value as string)
            : null,
          to: endFilter?.value ? new Date(endFilter.value as string) : null,
        },
      };
    }
  }
  // else, if an initial filter is set from FilterConfig, load that one
  if (
    useInitialValues &&
    dateRangePickerData &&
    (dateRangePickerData.initialFrom || dateRangePickerData.initialTo)
  ) {
    return {
      key,
      filterType,
      value: {
        from: dateRangePickerData.initialFrom || null,
        to: dateRangePickerData.initialTo || null,
      },
    };
  }
  return null;
};

const getDatePickerData = (
  key: string,
  filterType: TFilterType,
  datePickerData?: IFilterDatePickerData,
  _alreadySetFiltersByKey?: ISimpleFilter[]
): IFilterInitialValue | null => {
  // todo: set filter from already set filters
  if (datePickerData) {
    return {
      key,
      filterType,
      value: datePickerData.initialValue,
    };
  }
  return null;
};

const getDateTimePickerData = (
  key: string,
  filterType: TFilterType,
  dateTimePickerData?: IFilterDatePickerData,
  _alreadySetFiltersByKey?: ISimpleFilter[]
): IFilterInitialValue | null => {
  // todo: set filter from already set filters
  if (dateTimePickerData) {
    return {
      key,
      filterType,
      value: dateTimePickerData.initialValue,
    };
  }
  return null;
};

const getInputData = (
  key: string,
  filterType: TFilterType,
  inputData?: IFilterInputData,
  alreadySetFiltersByKey?: ISimpleFilter[]
): IFilterInitialValue | null => {
  if (alreadySetFiltersByKey?.length && alreadySetFiltersByKey[0].value) {
    return {
      key,
      filterType,
      value: alreadySetFiltersByKey[0].value,
    };
  }
  if (inputData) {
    return {
      key,
      filterType,
      value: inputData.initialValue,
    };
  }
  return null;
};

const getSelectData = (
  key: string,
  filterType: TFilterType,
  selectData?: IFilterSelectData,
  alreadySetFiltersByKey?: ISimpleFilter[],
  useInitialValues = false
): IFilterInitialValue | null => {
  if (alreadySetFiltersByKey?.length && alreadySetFiltersByKey[0].value) {
    return {
      key,
      filterType,
      value: alreadySetFiltersByKey[0].value,
    };
  }
  // use initial values
  if (useInitialValues && selectData && selectData.initialValue) {
    return {
      key,
      filterType,
      value: selectData.initialValue,
    };
  }
  return null;
};

const getMultiSelectData = (
  key: string,
  filterType: TFilterType,
  multiSelectData?: IFilterMultiSelectData,
  alreadySetFiltersByKey?: string[],
  useInitialValues = false,
  isExternalData = false
): IFilterInitialValue | null => {
  if (alreadySetFiltersByKey?.length && alreadySetFiltersByKey) {
    return {
      key,
      filterType,
      value: alreadySetFiltersByKey,
    };
  }
  if (
    !isExternalData &&
    useInitialValues &&
    multiSelectData &&
    multiSelectData.initialValue
  ) {
    const selectDataAsStrings: string[] =
      multiSelectData.values
        ?.filter((val) => multiSelectData.initialValue?.includes(val.value))
        .map((filter) => filter.value) || [];
    return {
      key,
      filterType,
      value: selectDataAsStrings,
    };
  }
  if (isExternalData && multiSelectData && multiSelectData.initialValue) {
    return {
      key,
      filterType,
      value: multiSelectData.initialValue,
    };
  }
  return null;
};

const getSwitchData = (
  key: string,
  filterType: TFilterType,
  switchData?: IFilterSwitchData,
  alreadySetFiltersByKey?: ISimpleFilter[],
  useInitialValues = false
): IFilterInitialValue | null => {
  const value = alreadySetFiltersByKey?.[0]?.value;

  if (value) {
    return {
      key,
      filterType,
      value: value === 'true' || value === true ? true : false,
    };
  }

  if (switchData && useInitialValues) {
    if (typeof switchData.initialValue === 'boolean') {
      return {
        key,
        filterType,
        value: switchData.initialValue,
      };
    }
  }
  return null;
};

const filterKey =
  (key: string, customApiKey?: string, customProperty?: string) =>
  (filter: ISimpleFilter) =>
    Boolean(
      customApiKey
        ? filter.key === customApiKey
        : customProperty
          ? filter.key.includes(key)
          : filter.key === key
    );

interface IGetInitialFilterDataProps {
  filterDefinitionList: IFilterDefinition[];
  alreadySetFilters?: ISimpleFilter[];
  initialFiltersSet?: boolean;
}

export const getInitialFilterData = ({
  filterDefinitionList,
  alreadySetFilters = [],
  initialFiltersSet = false,
}: IGetInitialFilterDataProps): IFilterInitialValue[] => {
  const initValues: IFilterInitialValue[] = [];
  filterDefinitionList.forEach(
    ({
      filterType,
      key,
      dateRangePickerData,
      selectData,
      multiSelectData,
      externalValueType,
      switchData,
      inputData,
      datePickerData,
      dateTimePickerData,
      customProperty,
      customApiKey,
      rangePickerData,
    }) => {
      switch (filterType) {
        case 'fromToNumber': {
          let alreadySetFiltersByKey: ISimpleFilter[] = [];
          if (rangePickerData?.keyFrom && rangePickerData?.keyTo) {
            alreadySetFiltersByKey = alreadySetFilters.filter(
              (filter) =>
                filter.key.includes(rangePickerData.keyFrom) ||
                filter.key.includes(rangePickerData.keyTo)
            );
          }
          const fromToNumberData = getFromToNumberData(
            key,
            filterType,
            rangePickerData,
            alreadySetFiltersByKey
          );
          if (fromToNumberData) {
            initValues.push(fromToNumberData);
          }
          break;
        }
        case 'fromToDate': {
          let alreadySetFiltersByKey: ISimpleFilter[] = [];
          if (key === 'startEnd' || key === 'createdAt') {
            alreadySetFiltersByKey = alreadySetFilters.filter(
              (filter) =>
                filter.key.includes('after') || filter.key.includes('before')
            );
          }
          const data = getDateRangePickerData(
            key,
            filterType,
            dateRangePickerData,
            alreadySetFiltersByKey,
            !initialFiltersSet
          );
          if (data) {
            initValues.push(data);
          }
          break;
        }
        case 'datePicker': {
          const alreadySetFiltersByKey: ISimpleFilter[] =
            alreadySetFilters.filter((filter) => filter.key.startsWith(key));
          const data = getDatePickerData(
            key,
            filterType,
            datePickerData,
            alreadySetFiltersByKey
          );
          if (data) {
            initValues.push(data);
          }
          break;
        }
        case 'dateTimePicker': {
          const alreadySetFiltersByKey: ISimpleFilter[] =
            alreadySetFilters.filter((filter) => filter.key.startsWith(key));
          const data = getDateTimePickerData(
            key,
            filterType,
            dateTimePickerData,
            alreadySetFiltersByKey
          );
          if (data) {
            initValues.push(data);
          }
          break;
        }
        case 'date':
        case 'input': {
          const alreadySetFiltersByKey: ISimpleFilter[] =
            alreadySetFilters.filter(
              filterKey(key, customApiKey, customProperty)
            );
          const data = getInputData(
            key,
            filterType,
            inputData,
            alreadySetFiltersByKey
          );
          if (data) {
            initValues.push(data);
          }
          break;
        }
        case 'multiSelectFreeFlow':
        case 'contactMultiSelect':
        case 'multiSelect': {
          const alreadySetFiltersByKey: string[] = alreadySetFilters
            .filter(filterKey(key, customApiKey, customProperty))
            .map((filter) => filter.value as string);
          const data = getMultiSelectData(
            key,
            filterType,
            multiSelectData,
            alreadySetFiltersByKey,
            !initialFiltersSet,
            !!externalValueType
          );
          if (data) {
            initValues.push(data);
          }
          break;
        }
        case 'select': {
          const alreadySetFiltersByKey: ISimpleFilter[] =
            alreadySetFilters.filter(
              filterKey(key, customApiKey, customProperty)
            );
          const data = getSelectData(
            key,
            filterType,
            selectData,
            alreadySetFiltersByKey,
            !initialFiltersSet
          );
          if (data) {
            initValues.push(data);
          }
          break;
        }
        case 'switch': {
          const alreadySetFiltersByKey: ISimpleFilter[] =
            alreadySetFilters.filter(
              filterKey(key, customApiKey, customProperty)
            );
          const data = getSwitchData(
            key,
            filterType,
            switchData,
            alreadySetFiltersByKey,
            !initialFiltersSet
          );
          if (data) {
            initValues.push(data);
          }
          break;
        }
        default:
          break;
      }
    }
  );
  return initValues;
};
