import { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Icons, Theme } from 'team-hero-ui';

import type { IWorkplannerSettingState } from 'components/Workplanner/Workplanner.interface';
import type { TWorkplannerViewMode } from './interfaces/viewmode.interface';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import {
  setFilters,
  setNextPage,
  setItemsPerPage,
  paginatedProjectShiftsSelector,
} from 'redux/table/projectShifts/projectShifts.slice';
import FilterRow from 'components/FilterRow';
import { usePagination } from 'hooks/usePagination.hook';
import useFilterComponent from 'components/FilterPanel/hooks/useFilterComponent.hook';
import { useFilters } from 'hooks/useFilters.hook';
import { ToolbarHeader } from 'components/ToolbarHeader';
import {
  BodyWrapperStyled,
  MainPanelStyled,
  WorkplannerWrapperStyled,
} from 'components/Workplanner/Workplanner.styled';
import ToggleViewMode from './addons/Header/components/ToggleViewMode';
import TimelineControls from './addons/Header/components/TimelineControls';
import WorkplannerBody from './components/WorkplannerBody';
import getWorkplannerSettingsByMission from './helper/getWorkplannerSettingsByMission.helper';
import { projectShiftFiltersFactory } from 'config/filterConfigurations/ProjectShiftFilters.config';
import { DownloadSectionDropdown } from 'components/TableView/addons/Header/components/DownloadSectionDropdown';
import useGetProjectShiftsExport from 'config/exportConfigurations/shiftsExport/useGetProjectShiftsExport.hook';
import { getDuringStringHelper } from 'helpers/dateTime/getDuringString.helper';
import { calculateTimeLineEnd } from 'components/Timeline/helper/timelineSettings.helper';

interface IWorkplannerProps {
  mission: IProjectMission;
}

const defaultWorkplannerSettingsState: IWorkplannerSettingState = {
  startDate: moment().startOf('isoWeek'),
  endDate: moment().endOf('isoWeek'),
  timelineSize: '1week',
  viewMode: 'timeline',
};

const Workplanner: FC<IWorkplannerProps> = ({ mission }) => {
  const { t } = useTranslation('general');

  const missionStartDateRef = useRef(mission.start);

  const [workplannerSettings, setWorkplannerSettings] =
    useState<IWorkplannerSettingState>(() =>
      getWorkplannerSettingsByMission(
        moment(),
        mission,
        defaultWorkplannerSettingsState
      )
    );

  // update timeline start date when mission is changed via dropdown in work planner header
  useEffect(() => {
    if (missionStartDateRef.current !== mission.start) {
      setWorkplannerSettings((prevState) => {
        return getWorkplannerSettingsByMission(moment(), mission, prevState);
      });
      missionStartDateRef.current = mission.start;
    }
  }, [mission]);

  const setViewMode = (viewMode: TWorkplannerViewMode) => {
    setWorkplannerSettings((prevState) => {
      return {
        ...prevState,
        viewMode,
      };
    });
  };

  const paginationData = usePagination(
    1,
    setNextPage,
    setItemsPerPage,
    paginatedProjectShiftsSelector
  );

  const filterData = useFilters(
    setFilters,
    paginatedProjectShiftsSelector,
    paginationData.onResetPage
  );

  const {
    toggleFilterVisibility,
    FilterComponent,
    FilterChipsComponent,
    filtersAreSet,
  } = useFilterComponent({
    filterData,
    filterDefinitionList: projectShiftFiltersFactory({ t }),
    type: 'modal',
    initialIsFilterVisible: false,
  });

  const { getMissionShiftsExport, isLoading: isExportLoading } =
    useGetProjectShiftsExport({ missionId: mission.id });

  const onXLSExportClick = () => {
    const { startDate, timelineSize } = workplannerSettings;

    getMissionShiftsExport(
      getDuringStringHelper(
        startDate.toDate(),
        calculateTimeLineEnd(timelineSize, startDate).toDate()
      )
    );
  };

  return (
    <WorkplannerWrapperStyled>
      {FilterComponent}
      <MainPanelStyled>
        <ToolbarHeader
          leftSection={
            <>
              <Button
                color='toolbar'
                onClick={toggleFilterVisibility}
                data-test-id='open-filters-button'
              >
                <Icons.FilterIcon
                  svgColor={Theme.colors.greyShades.grey2}
                  svgSize={17}
                />
              </Button>
              <ToggleViewMode
                value={workplannerSettings.viewMode}
                setValue={setViewMode}
              />
            </>
          }
          centerSection={
            <TimelineControls
              timelineSettings={workplannerSettings}
              setTimelineSettings={setWorkplannerSettings}
            />
          }
          rightSection={
            <DownloadSectionDropdown
              handleOnXLSDownload={onXLSExportClick}
              disabled={isExportLoading}
            />
          }
          secondRow={
            filtersAreSet && <FilterRow>{FilterChipsComponent}</FilterRow>
          }
        />
        <BodyWrapperStyled>
          <WorkplannerBody
            mission={mission}
            workplannerSettings={workplannerSettings}
          />
        </BodyWrapperStyled>
      </MainPanelStyled>
    </WorkplannerWrapperStyled>
  );
};

export default Workplanner;
