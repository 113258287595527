import type { TRootState } from 'store';
import type { ITableSelector } from 'interfaces/Table/selector.interface';
import { createTableSelectorSlice } from 'redux/table/helper/createTableSelector.slice';
import {
  itemsPerPageDefault,
  currentPageDefault,
  createdAtSortingDefault,
} from 'redux/table/consts/tableDefaults.consts';

export const jobAdsSlice = createTableSelectorSlice(
  'paginatedJobAds',
  currentPageDefault,
  itemsPerPageDefault,
  createdAtSortingDefault
);

const { actions, reducer } = jobAdsSlice;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
  setInitialFiltersSet,
  setMenuColumnList,
} = actions;

export const paginatedJobAdsSelector = (state: TRootState): ITableSelector =>
  state.tableJobAds;

export default reducer;
