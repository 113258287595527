import { FC, useEffect, useState } from 'react';
import { Notification as NotificationMessage } from 'team-hero-ui';

import {
  clearNotification,
  notificationSelector,
} from 'redux/notification/notification.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Notification: FC = () => {
  const { type, message } = useAppSelector(notificationSelector);
  const [open, setOpen] = useState(!!message);
  const dispatch = useAppDispatch();

  const handleOnClose = () => {
    setOpen(false);
    dispatch(clearNotification());
  };

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  return (
    <>
      {message && (
        <NotificationMessage
          open={open}
          type={type || 'success'}
          onClose={handleOnClose}
        >
          {message}
        </NotificationMessage>
      )}
    </>
  );
};

export default Notification;
