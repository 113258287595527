import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

export const ProjectTitleTagStyled = styled.div`
  overflow: hidden;
  margin-right: ${pxToRem(4)};
  & > div {
    width: 100%;
  }
`;
