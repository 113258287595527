import { ReactElement } from 'react';

import { BeginSeparatorCellRendererStyled } from './BeginSeparatorCellRenderer.styled';

export const BeginSeparatorCellRenderer = (
  onClick?: () => void,
  disabled?: boolean
): ReactElement => {
  return (
    <BeginSeparatorCellRendererStyled disabled={disabled} onClick={onClick} />
  );
};
