import type { ISectionColumn } from '../modalConfig.interface';

export const emptyField: ISectionColumn = {
  key: 'empty',
  items: [
    [
      {
        renderType: 'empty',
        propertyName: '',
      },
    ],
  ],
};
