import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TRootState } from 'store';
import type { ITwoFactorState } from './twoFactor.slice.type';

export const initialState: ITwoFactorState = {
  twoFactorToken: '',
  twoFactorExpire: '',
  availableChannels: [],
  defaultChannel: 'email',
};

export const twoFactorSlice = createSlice({
  name: 'twoFactor',
  initialState,
  reducers: {
    setTwoFactorState: (
      state: ITwoFactorState,
      action: PayloadAction<ITwoFactorState>
    ) => {
      state.twoFactorExpire = action.payload.twoFactorExpire;
      state.twoFactorToken = action.payload.twoFactorToken;
      state.availableChannels = action.payload.availableChannels;
      state.defaultChannel = action.payload.defaultChannel;
    },
    setTwoFactorToken: (
      state: ITwoFactorState,
      action: PayloadAction<string>
    ) => {
      state.twoFactorToken = action.payload;
    },
    setTwoFactorExpire: (
      state: ITwoFactorState,
      action: PayloadAction<string>
    ) => {
      state.twoFactorExpire = action.payload;
    },
    resetTwoFactorState: () => initialState,
  },
});

export const {
  setTwoFactorState,
  setTwoFactorExpire,
  resetTwoFactorState,
  setTwoFactorToken,
} = twoFactorSlice.actions;

export const twoFactorSelector = (state: TRootState): ITwoFactorState =>
  state.twoFactor;

export default twoFactorSlice.reducer;
