import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { device, Icons, Utils } from 'team-hero-ui';

import NavigationActionCard from 'components/NavigationActionCard';
import {
  HelpLinkStyled,
  SearchActionCardsStyled,
  SearchActionCardsWrapperStyled,
} from './SearchActionCards.styled';
import { ISearchActionCardsProps } from './SearchActionCards.types';
import Avatar from 'components/Avatar';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import { useGetSystemSettings } from 'hooks/useGetSystemSettings';
import { IActionCardSearch } from 'components/NavigationActionCard/NavigationActionCard.types';

const { useMediaQuery } = Utils;

const SearchActionCards: FC<ISearchActionCardsProps> = ({
  searchValue,
  onActionSelect,
}) => {
  const isMdScreenSize = useMediaQuery(device.md);
  const navigate = useNavigate();
  const { currentUserContact } = useCurrentUserContact();
  const { supportUrl } = useGetSystemSettings();
  const isSmScreenSize = useMediaQuery(device.sm);

  const actionCards = useMemo(() => {
    const actions: IActionCardSearch[] = [
      {
        title: 'profile',
        searchKeys: ['profile'],
        icon: (
          <Avatar
            sourceType='file'
            images={currentUserContact?.avatar || ''}
            firstName={currentUserContact?.firstName}
            lastName={currentUserContact?.lastName}
            thumbnailSize={isMdScreenSize ? 'medium' : 'small'}
          />
        ),
        onClick: () => navigate('profile'),
      },
      {
        title: 'jobs',
        searchKeys: ['jobs', 'work', 'shifts'],
        icon: <Icons.ShiftIcon svgSize={40} />,
        onClick: () => navigate('work'),
      },
      {
        title: 'calendar',
        searchKeys: [
          'calendar',
          'kalender',
          'availabilities',
          'verfügbarkeiten',
        ],
        icon: <Icons.CalendarIcon svgSize={40} />,
        onClick: () => navigate('profile-calendar?view=calendar'),
      },
      {
        title: 'absences',
        searchKeys: [
          'absences',
          'abwesenheiten',
          'urlaub',
          'vacation',
          'holiday',
        ],
        icon: <Icons.VacationRequestIcon svgSize={40} />,
        onClick: () => navigate('profile-calendar?view=absence'),
      },
      {
        title: 'timetrackings',
        searchKeys: ['zeiterfassung', 'timetrackings', 'zeiten'],
        icon: <Icons.ClockIcon svgSize={40} />,
        onClick: () => navigate('profile?modal=timetrackings'),
      },
      {
        title: 'documents',
        searchKeys: ['documents', 'dokumente'],
        icon: <Icons.MissingContractIcon svgSize={40} />,
        onClick: () => navigate('profile?modal=documents'),
      },
      {
        title: 'messages',
        searchKeys: ['messages', 'nachrichten'],
        icon: <Icons.MessageUniversalIcon svgSize={40} />,
        onClick: () => navigate('messages'),
      },
      {
        title: 'expenses',
        searchKeys: ['expenses', 'auslagen'],
        icon: <Icons.DocumentIcon svgSize={40} />,
        onClick: () => navigate('profile?modal=expenses'),
      },
      {
        title: 'payslips',
        searchKeys: ['payslips', 'lohn'],
        icon: <Icons.PayrollIcon svgSize={40} />,
        onClick: () => navigate('profile?modal=payslips'),
      },
      {
        title: 'invoices',
        searchKeys: ['invoices', 'rechnungen'],
        icon: <Icons.ReadyToPayIcon svgSize={40} />,
        onClick: () => navigate('profile?modal=invoices'),
      },
      {
        title: 'help',
        searchKeys: ['help', 'hilfe'],
        icon: (
          <HelpLinkStyled
            href={supportUrl}
            rel='noopener noreferrer'
            target='_blank'
          >
            <Icons.OnlineHelpIcon svgSize={40} />
          </HelpLinkStyled>
        ),
      },
      {
        title: 'logout',
        searchKeys: ['logout', 'ausloggen', 'abmelden', 'quit'],
        icon: <Icons.LogoutIcon svgSize={40} />,
        onClick: () => navigate('logout'),
      },
    ];

    if (!searchValue) {
      return actions;
    }
    // filter action by searchvalue in searchKey
    const filteredActions = actions.filter((action) =>
      action.searchKeys.some((key) => new RegExp(searchValue, 'i').test(key))
    );
    return filteredActions || [];
  }, [
    currentUserContact?.avatar,
    currentUserContact?.firstName,
    currentUserContact?.lastName,
    isMdScreenSize,
    navigate,
    searchValue,
    supportUrl,
  ]);

  const actionCardsLength = actionCards.length;

  return actionCardsLength ? (
    <SearchActionCardsWrapperStyled>
      <SearchActionCardsStyled
        $isFiltered={!!searchValue}
        $isSmScreenSize={isSmScreenSize}
      >
        {actionCards.map((actionCard) => (
          <NavigationActionCard
            key={actionCard.title}
            icon={actionCard.icon}
            onClick={() => {
              actionCard.onClick?.();
              onActionSelect();
            }}
            title={actionCard.title}
            buttonStyle='ripple'
          />
        ))}
      </SearchActionCardsStyled>
    </SearchActionCardsWrapperStyled>
  ) : null;
};

export default SearchActionCards;
