import { TFunction } from 'i18next';
import { Theme } from 'team-hero-ui';

import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import { BeginSeparatorCellRenderer } from 'components/cellRenderers/BeginSeparatorCellRenderer/BeginSeparatorCellRenderer';
import { CompanyNameTagCellRenderer } from 'components/cellRenderers/CompanyNameTagCellRenderer/CompanyNameTagCellRenderer';
import { TextCellRenderer } from 'components/cellRenderers/TextCellRenderer/TextCellRenderer';
import {
  CompanyDocumentDownloadCellRenderer,
  DocumentTodoCellRenderer,
  DocumentStatusCellRenderer,
} from 'components/cellRenderers/document';
import { DateCellRenderer } from 'components/cellRenderers/DateCellRenderer/DateCellRenderer';

const customWidths = {
  S: '100px',
  M: '200px',
  L: '300px',
};

interface ICompanyDocumentsColumnsFactoryProps {
  t: TFunction;
}

export const columnsOffset = 2;

export const companyDocumentsColumnsFactory = ({
  t,
}: ICompanyDocumentsColumnsFactoryProps): IColumnDefinitionType<ICompanyDocument>[] => {
  return [
    {
      key: 'beginSeparator',
      label: '',
      visibility: true,
      width: '14px',
      cellType: 'custom',
      skeletonType: 'status',
      cellRenderer: () => BeginSeparatorCellRenderer(),
    },
    {
      key: 'company',
      label: t('table.header.labels.company'),
      cellType: 'custom',
      skeletonType: 'text',
      visibility: true,
      minWidth: customWidths.L,
      cellRenderer: ({ company }) => (
        <CompanyNameTagCellRenderer companyIri={company} />
      ),
    },
    {
      key: 'templateTitle',
      label: t('table.header.labels.title'),
      visibility: true,
      minWidth: customWidths.L,
      cellType: 'custom',
      cellRenderer: (companyDocument) =>
        TextCellRenderer({ text: companyDocument?.template.title }),
    },
    {
      key: 'unlimitedPeriod',
      label: t('table.header.labels.unlimitedPeriod'),
      visibility: false,
      displayInExport: true,
      cellType: 'custom',
      cellExportHandler: ({ unlimitedPeriod }) =>
        unlimitedPeriod ? t('common.yes') : t('common.no'),
    },
    {
      key: 'start',
      label: t('table.header.labels.start'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      isSortable: true,
      sortType: 'date',
      width: customWidths.M,
      cellRenderer: ({ start }) =>
        DateCellRenderer({ date: start, withDayOfWeek: true }),
      cellExportHandler: ({ start }) => {
        return start ? new Date(start) : '-';
      },
    },
    {
      key: 'end',
      label: t('table.header.labels.end'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      width: customWidths.M,
      isSortable: true,
      sortType: 'date',
      cellRenderer: ({ end, isExpired }) =>
        DateCellRenderer({
          date: end,
          showTime: false,
          withDayOfWeek: true,
          noDateMessage: t('table.labels.indefinitely'),
          color: isExpired
            ? Theme.colors.primary.red
            : Theme.colors.primary.grey,
        }),
      cellExportHandler: ({ end }) => {
        return end ? new Date(end) : '-';
      },
    },
    {
      key: 'documentStatus',
      label: t('table.header.labels.documentStatus'),
      visibility: true,
      width: customWidths.L,
      cellType: 'custom',
      cellRenderer: (companyDocument) => (
        <DocumentStatusCellRenderer document={companyDocument} />
      ),
      cellExportHandler: ({ signatureStatus }) =>
        signatureStatus ? t(`signatureStatus.${signatureStatus}`) : '-',
    },
    {
      key: 'todo',
      label: t('table.header.labels.status'),
      visibility: true,
      displayInMenu: true,
      width: '100px',
      minWidth: '100px',
      cellType: 'custom',
      cellRenderer: ({
        isExpired,
        isMissing,
        signatureStatus,
        isApproved,
        file,
      }) => (
        <DocumentTodoCellRenderer
          isApproved={isApproved}
          isExpired={isExpired}
          isMissing={isMissing}
          signatureStatus={signatureStatus}
          file={file}
        />
      ),
    },
    {
      key: 'download',
      label: '',
      visibility: true,
      width: '50px',
      minWidth: '50px',
      cellType: 'custom',
      cellRenderer: (row) => (
        <CompanyDocumentDownloadCellRenderer document={row} />
      ),
    },
  ];
};
