import { Link } from 'react-router';
import { styled, css } from 'styled-components';

const linkCommonStyles = css`
  text-decoration: none;
  font-size: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: unset;
  }
`;

export const LinkStyled = styled(Link)`
  width: inherit;
  ${linkCommonStyles};
`;

export const LinkUnderlineStyled = styled(LinkStyled)`
  text-decoration: underline;
  max-width: 100%;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: underline;
  }
`;

export const LinkUnstyled = styled(Link)`
  cursor: pointer;
  color: inherit;
  text-decoration: none; /* Removes underline */
`;

export const LinkHrefStyled = styled.a`
  ${linkCommonStyles};
`;
