import { FC, useState } from 'react';
import {
  DragDropContext,
  DragStart,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Button, Grid2, Icons, Theme } from 'team-hero-ui';

import {
  IShiftApplication,
  TShiftApplicationClientStatus,
} from 'interfaces/ShiftApplication.interface';
import ApplicationArea from './ApplicationArea';
import ApplicationCard from './ApplicationCard';
import {
  ApplicationAreasStyled,
  ApplicationPoolStyled,
  ApplicationsStyled,
  PoolToCheckStyled,
} from './ApplicationPool.styled';
import type { IApplicationPoolProps } from './ApplicationPool.types';
import useSetApplicationClientStatus from '../../hooks/useSetApplicationClientStatus.hook';
import {
  getApplicationIdsWithSameContact,
  getUniqueApplications,
} from '../../helpers/applications.helper';
import useApplicationSedcard from '../ApplicationSedcard/useApplicationSedcard.hook';

const iconSize = 24;

const ApplicationPool: FC<IApplicationPoolProps> = ({
  missionId,
  allApplications,
  selectedApplications,
}) => {
  const { t } = useTranslation('general');
  const { applicationSedcardModal, openApplicationSedcardModal } =
    useApplicationSedcard({ allApplications });
  const [dragItemId, setDragItemId] = useState('');

  const { setApplicationClientStatus } = useSetApplicationClientStatus({
    missionId,
  });

  const onDragStart = (initial: DragStart) => {
    setDragItemId(initial.draggableId);
  };

  const onDragEnd = ({ draggableId, destination, source }: DropResult) => {
    if (
      !dragItemId ||
      !destination ||
      !draggableId ||
      destination?.droppableId === source?.droppableId
    ) {
      return;
    }

    const destinationArea = destination.droppableId;
    const applicationId = parseInt(draggableId, 10);

    if (['favorite', 'okay', 'nofit'].includes(destinationArea)) {
      setApplicationClientStatus({
        applicationIds: getApplicationIdsWithSameContact(
          selectedApplications,
          applicationId
        ),
        status: destinationArea as TShiftApplicationClientStatus,
      });
    }

    setDragItemId('');
  };

  const onAvatarClick = (application: IShiftApplication) => {
    openApplicationSedcardModal({
      application,
      missionId,
      contactConnectedApplicationIds: getApplicationIdsWithSameContact(
        allApplications,
        application.id
      ),
    });
  };

  const onMarkAllAsOkay = () => {
    setApplicationClientStatus({
      applicationIds: allApplications
        .filter((application) => application.clientStatus === 'open')
        .map((application) => application.id),
      status: 'okay',
    });
  };

  const uniqueApplications = getUniqueApplications(selectedApplications);

  const openApplications = uniqueApplications.filter(
    (application) => application.clientStatus === 'open'
  );

  const favouriteApplications = uniqueApplications.filter(
    (application) => application.clientStatus === 'favorite'
  );

  const okApplications = uniqueApplications.filter(
    (application) => application.clientStatus === 'okay'
  );

  const noFitApplications = uniqueApplications.filter(
    (application) => application.clientStatus === 'nofit'
  );

  return (
    <ApplicationPoolStyled>
      {applicationSedcardModal}
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Grid2 container spacing={4} size={12} flexGrow={1}>
          <Grid2
            size={{ xs: 5, xl: 3 }}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
          >
            {!!openApplications.length && (
              <PoolToCheckStyled>
                {openApplications.length}{' '}
                {t('clientLogin.applications.poolToCheck')}
              </PoolToCheckStyled>
            )}
            <Droppable droppableId='none' isDropDisabled>
              {(provided) => (
                <ApplicationsStyled
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <>
                    {openApplications.map((application, index) => (
                      <ApplicationCard
                        missionId={missionId}
                        allApplications={allApplications}
                        key={application.id}
                        application={application}
                        index={index}
                        onAvatarClick={onAvatarClick}
                      />
                    ))}
                    {provided.placeholder}
                  </>
                </ApplicationsStyled>
              )}
            </Droppable>
            {!!openApplications.length && (
              <Button
                color='primary'
                startIcon={<Icons.CheckIconV2 svgSize={20} />}
                onClick={onMarkAllAsOkay}
              >
                {t('clientLogin.applications.markAllAsOkay')}
              </Button>
            )}
          </Grid2>
          <Grid2 size={{ xs: 7, xl: 9 }} display='flex' flexDirection='column'>
            <ApplicationAreasStyled>
              <Droppable droppableId='favorite'>
                {(provided, snapshot) => (
                  <ApplicationArea
                    type='favorite'
                    provided={provided}
                    snapshot={snapshot}
                    applications={favouriteApplications}
                    onAvatarClick={onAvatarClick}
                    icon={
                      <Icons.PollFavourite
                        svgSize={iconSize}
                        svgColor={Theme.colors.primary.green}
                      />
                    }
                    title={t('clientLogin.applications.favourite')}
                  />
                )}
              </Droppable>
              <Droppable droppableId='okay'>
                {(provided, snapshot) => (
                  <ApplicationArea
                    type='okay'
                    provided={provided}
                    snapshot={snapshot}
                    applications={okApplications}
                    onAvatarClick={onAvatarClick}
                    icon={
                      <Icons.PollOK
                        svgSize={iconSize}
                        svgColor={Theme.colors.primary.green}
                      />
                    }
                    title={t('clientLogin.applications.okay')}
                  />
                )}
              </Droppable>
              <Droppable droppableId='nofit'>
                {(provided, snapshot) => (
                  <ApplicationArea
                    type='nofit'
                    provided={provided}
                    snapshot={snapshot}
                    applications={noFitApplications}
                    onAvatarClick={onAvatarClick}
                    icon={
                      <Icons.PollNoFit
                        svgSize={iconSize}
                        svgColor={Theme.colors.primary.red}
                      />
                    }
                    title={t('clientLogin.applications.noFit')}
                  />
                )}
              </Droppable>
            </ApplicationAreasStyled>
          </Grid2>
        </Grid2>
      </DragDropContext>
    </ApplicationPoolStyled>
  );
};

export default ApplicationPool;
