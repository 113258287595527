import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundIcon, theme } from 'team-hero-ui';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router';

import {
  ContainerStyled,
  ContentWrapperStyled,
  LogoWrapperStyled,
  DescriptionStyled,
  ErrorH1,
  ErrorH2,
  ReportButtonStyled,
  ItemStyled,
  LogoItemStyled,
} from './LayoutError.styled';
import { useToggle } from 'hooks/useToggle.hook';

interface ILayoutErrorProps {
  displayReportButton?: boolean;
  headline?: string;
  subHeadline?: string;
  description?: string;
  eventId: string;
  resetError?: () => void;
  displayFlexColumn?: boolean;
  shouldResetOnLocationChange?: boolean;
}

const LayoutError: FC<ILayoutErrorProps> = ({
  displayReportButton = false,
  headline,
  subHeadline,
  description,
  eventId,
  resetError,
  displayFlexColumn = false,
  shouldResetOnLocationChange = true,
}) => {
  const location = useLocation();
  // // save current locationKey in ref for comparison in useEffect
  const prevKeyRef = useRef<string>(location.key);

  // if location.key changes, reset the error
  useEffect(() => {
    if (prevKeyRef.current !== location.key && shouldResetOnLocationChange) {
      resetError?.();
    }
    prevKeyRef.current = location.key;
  }, [resetError, location.key, shouldResetOnLocationChange]);

  const { t } = useTranslation('errors');
  const { toggleValue: reportPressed, on: reportPressedOn } = useToggle(false);
  const SentryClient = Sentry.getClient();

  return (
    <ContainerStyled $displayFlexColumn={displayFlexColumn}>
      <LogoItemStyled>
        <LogoWrapperStyled>
          <NotFoundIcon
            svgSize='100%'
            svgColor={theme.colors.greyShades.grey2}
          />
        </LogoWrapperStyled>
      </LogoItemStyled>
      <ItemStyled>
        <ContentWrapperStyled>
          <ErrorH1>{headline || t('errorPage.headline')}</ErrorH1>
          <ErrorH2>{subHeadline || t('errorPage.subHeadline')}</ErrorH2>
          <DescriptionStyled>
            {description || t('errorPage.description')}
          </DescriptionStyled>
          {displayReportButton && SentryClient && !reportPressed && (
            <ReportButtonStyled
              color='primary'
              onClick={() => {
                Sentry.showReportDialog({ eventId });
                reportPressedOn();
              }}
            >
              {t('errorPage.reportError')}
            </ReportButtonStyled>
          )}
        </ContentWrapperStyled>
      </ItemStyled>
    </ContainerStyled>
  );
};

export default LayoutError;
