import { ReactElement, useCallback, useState } from 'react';

import type { IId } from 'interfaces/IId.interface';
import type { TBulkEditRowPosition } from 'components/Table/components/BulkEditRow/BulkEditRow.type';
import { useToggle } from 'hooks/useToggle.hook';
import { BulkEditRow } from 'components/Table/components/BulkEditRow/BulkEditRow';

export interface IRowSelectionProps {
  rowIdMenuOpen: number | null;
  areCheckboxesVisible: boolean;
  selectedRowsIds: number[];
  setRowIdMenuOpen: (id: number | null) => void;
  onHandleRowSelection: (id: number) => void;
  onMenuClose: () => void;
  toggleCheckboxesVisibility: () => void;
}

interface IUseRowSelectionReturn {
  rowSelectionProps: IRowSelectionProps;
  bulkEdit: (position: TBulkEditRowPosition) => ReactElement | null;
}

export const useRowSelection = <DataType extends IId>(
  items?: DataType[],
  onClickDelete?: (selectedIds: number[]) => void,
  additionalBulkActions?: (
    selectedRowsIds: number[],
    rowPosition: TBulkEditRowPosition
  ) => ReactElement
): IUseRowSelectionReturn => {
  const {
    toggle: toggleCheckboxesVisibility,
    off: hideCheckboxes,
    toggleValue: areCheckboxesVisible,
  } = useToggle();

  const [rowIdMenuOpen, setRowIdMenuOpen] = useState<null | number>(null);
  const [selectedRowsIds, setSelectedRowIds] = useState<number[]>([]);

  const onHandleRowSelection = useCallback(
    (id: number) => {
      if (!selectedRowsIds.find((i) => i === id)) {
        return setSelectedRowIds([id, ...selectedRowsIds]);
      }
      if (id === rowIdMenuOpen) {
        return setSelectedRowIds([]);
      }
      return setSelectedRowIds(selectedRowsIds.filter((i) => i !== id));
    },
    [rowIdMenuOpen, selectedRowsIds]
  );

  const onSelectAll = useCallback(() => {
    if (items) {
      const ids = items.map((i) => i.id);
      setSelectedRowIds(ids);
    }
  }, [items]);

  const onDeselectAll = useCallback(() => {
    setSelectedRowIds([]);
  }, []);

  const onMenuClose = useCallback(() => {
    onDeselectAll();
    setRowIdMenuOpen(null);
    hideCheckboxes();
  }, [hideCheckboxes, onDeselectAll]);

  const onDelete = useCallback(() => {
    if (onClickDelete) {
      onClickDelete(selectedRowsIds);
    }
  }, [onClickDelete, selectedRowsIds]);

  const bulkEdit = useCallback(
    (position: TBulkEditRowPosition): ReactElement | null => {
      return areCheckboxesVisible ? (
        <BulkEditRow
          onSelectAll={onSelectAll}
          onDeselectAll={onDeselectAll}
          onCancel={onMenuClose}
          onDelete={onClickDelete && onDelete}
          rowPosition={position}
          additionalBulkActions={
            additionalBulkActions &&
            additionalBulkActions(selectedRowsIds, position)
          }
        />
      ) : null;
    },
    [
      areCheckboxesVisible,
      onSelectAll,
      onDeselectAll,
      onMenuClose,
      onClickDelete,
      onDelete,
      additionalBulkActions,
      selectedRowsIds,
    ]
  );

  return {
    rowSelectionProps: {
      setRowIdMenuOpen,
      onHandleRowSelection,
      areCheckboxesVisible,
      selectedRowsIds,
      onMenuClose,
      toggleCheckboxesVisibility,
      rowIdMenuOpen,
    },
    bulkEdit,
  };
};
