import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { IMissionRatingsTabProps } from './MissionRatingsTab.types';
import type { IRating } from 'interfaces/Rating.interface';
import { TableView } from 'components/TableView/TableView';
import {
  setSorting,
  setNextPage,
  setItemsPerPage,
  paginatedRatingsSelector,
  setMenuColumnList,
} from 'redux/table/ratings/ratings.slice';
import { useAppSelector } from 'store/hooks';
import { useGetRatingsQuery } from 'services/teamHeroApi.service';
import { useRowSelection } from 'components/TableView/hooks/useRowSelection.hook';
import {
  columnsOffset,
  ratingsColumnsFactory,
} from 'config/tableColumnConfigurations/RatingsColumns.config';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import { usePagination } from 'hooks/usePagination.hook';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';
import { useSort } from 'components/TableView/hooks/useSort.hook';
import { useRatingsSummaryRow } from './hooks/useRatingsSummaryRow.hook';

const MissionRatingsTab: FC<IMissionRatingsTabProps> = ({ missionId }) => {
  const { t } = useTranslation('general');

  const { pagination, sorting } = useAppSelector(paginatedRatingsSelector);

  const { enableClientContacts } = useClientLoginMissionSettings({ missionId });

  const {
    data: ratings,
    isLoading,
    isFetching,
    isError,
  } = useGetRatingsQuery({
    ...createPaginationApiArgs(pagination, sorting),
    filters: [{ key: 'mission', operator: 'AND', value: missionId }],
  });

  const paginationData = usePagination(
    1,
    setNextPage,
    setItemsPerPage,
    paginatedRatingsSelector
  );

  const sortingData = useSort(setSorting, paginatedRatingsSelector);

  const { rowSelectionProps, bulkEdit } = useRowSelection<IRating>(
    ratings?.items
  );

  const { summaryBottomRow } = useRatingsSummaryRow({
    ratings: ratings?.items || [],
    enableClientContacts,
  });

  if (isError) {
    throw new Error(t('errors:errorOccurred'));
  }

  return (
    <>
      <TableView
        columns={ratingsColumnsFactory({
          enableClientContacts,
          t,
        })}
        bulkEditRow={bulkEdit}
        data={ratings?.items || []}
        isLoading={isLoading}
        isFetching={isFetching}
        itemsTotalCount={ratings?.totalCount || 0}
        pagination={paginationData}
        rowIdMenuOpen={rowSelectionProps.rowIdMenuOpen}
        columnsOffset={columnsOffset}
        sorting={sortingData}
        isMin={false}
        paginationSelector={paginatedRatingsSelector}
        setColumnListDispatch={setMenuColumnList}
        disableColumnSelection
        bottomRowRenderer={summaryBottomRow}
      />
    </>
  );
};

export default MissionRatingsTab;
