import {
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  IAutoSelectOption,
  Select,
  SelectAutocomplete,
  SelectGroupDivider,
  SelectItem,
} from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import type { ISelectValue } from 'interfaces/Data/SelectValue.interface';
import type { ISelectRendererProps } from 'components/FilterPanel/FilterPanel.type';
import type { IOptionGroupWithItems } from 'components/Modals/AddEditModal/ModalSection/fields/SelectField/SelectField.types';
import { useGetSelectExternalOptionValues } from 'hooks/getSelectExternalOptionValues/getSelectExternalOptionValues.hook';
import { createOptionSelectGroups } from 'components/Modals/AddEditModal/ModalSection/fields/SelectField/helper/selectOptions.helper';

const defaultArray: [] = [];

export const SelectRenderer: FC<ISelectRendererProps> = ({
  label,
  staticValues,
  externalValuesType,
  value,
  onChange,
  styleType,
  disabled = false,
  disableNoValue = false,
  externalValuesAdditionalFilters = defaultArray,
  optionGroups = defaultArray,
  withAutocomplete = false,
}) => {
  const { t } = useTranslation('general');
  const [items, setItems] = useState<ISelectValue[]>([]);
  const selectLabel = t('selectValue', { filterLabel: label });

  const { data: externalSelectItems, isLoading } =
    useGetSelectExternalOptionValues(
      externalValuesType,
      false,
      externalValuesAdditionalFilters
    );

  useEffect(() => {
    if (staticValues) {
      setItems(staticValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOptions = externalValuesType ? externalSelectItems : items;

  const selectItemList = (): ReactElement[] => {
    // if optionGroups are defined, return the grouped selectItems
    // not grouped items, will be put to others group
    if (optionGroups.length > 0) {
      const optionSelectGroups = createOptionSelectGroups(
        optionGroups,
        selectOptions,
        t
      );
      const optionsReturn: ReactElement[] = [];
      optionSelectGroups.forEach((optionGroup: IOptionGroupWithItems) => {
        if (optionGroup.values.length === 0) return;
        optionsReturn.push(
          <SelectGroupDivider
            key={`group-divider_${optionGroup.label}`}
            title={optionGroup.label}
          />
        );
        optionGroup.values.forEach((item) => {
          optionsReturn.push(
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          );
        });
      });
      return optionsReturn;
    }
    // if no optionGroups are defined, return the default selectItems
    return selectOptions.map((item) => {
      return (
        <SelectItem key={item.value} value={item.value}>
          {item.label}
        </SelectItem>
      );
    });
  };

  const onChangeCallback = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      changeValue: IAutoSelectOption
    ) => {
      if (changeValue) {
        onChange(changeValue.value);
      } else {
        onChange('');
      }
    },
    [onChange]
  );

  const selectItemListAutocomplete = useMemo((): IAutoSelectOption[] => {
    return selectOptions.map((item) => ({
      value: item.value,
      label: item.label,
    }));
  }, [selectOptions]);

  if (withAutocomplete) {
    return (
      <SelectAutocomplete
        disabled={isLoading || disabled}
        label={selectLabel}
        value={value || null}
        onChange={onChangeCallback}
        styleType={styleType === 'modal' ? styleType : 'filter'}
        options={selectItemListAutocomplete}
        onInputChange={() => {}}
        freeSolo={false}
      />
    );
  }

  return (
    <Select
      disabled={isLoading || disabled}
      label={selectLabel}
      value={value}
      onChange={onChange}
      styleType={styleType === 'modal' ? styleType : 'filter'}
    >
      {!disableNoValue && (
        <SelectItem key='noValue' value=''>
          {t('select.noValue')}
        </SelectItem>
      )}
      {selectItemList()}
    </Select>
  );
};
