import { FC } from 'react';
import { useSearchParams } from 'react-router';
import { Pagination } from 'team-hero-ui';

import type { IClientLoginConversationListProps } from './ClientLoginConversationList.types';
import {
  paginatedConversationsSelector,
  setNextPage,
  setItemsPerPage,
} from 'redux/collections/conversations/conversations.slice';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import {
  ConversationListItemsStyled,
  ConversationListStyled,
  ConversationListWrapperStyled,
} from './ClientLoginConversationList.styled';
import { PaginationStyled } from 'components/Modals/Modals.styled';
import { useAppSelector } from 'store/hooks';
import { calculatePagesCount } from 'pages/Work/Work.helpers';
import { usePagination } from 'hooks/usePagination.hook';
import NoResultGraphic from 'components/NoResultGraphic';
import { ConversationItem } from 'pages/Messages/ConversationList/ConversationItem';

const ClientLoginConversationList: FC<IClientLoginConversationListProps> = ({
  conversations,
  conversationsTotalCount,
}) => {
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get('conversation') || '';

  const { currentUserContact } = useCurrentUserContact();

  const { pagination } = useAppSelector(paginatedConversationsSelector);

  const { page, itemsPerPage, onChangePage } = usePagination(
    pagination.currentPage,
    setNextPage,
    setItemsPerPage,
    paginatedConversationsSelector
  );

  return (
    <ConversationListStyled>
      <ConversationListWrapperStyled data-test-id='conversations-wrapper'>
        <ConversationListItemsStyled>
          {conversations.length === 0 && <NoResultGraphic />}
          {conversations.map((conversation) => (
            <div key={conversation.id}>
              <ConversationItem
                conversation={conversation}
                conversationIdQueryParam={conversationId}
                currentUserContact={currentUserContact}
              />
            </div>
          ))}
        </ConversationListItemsStyled>
        <PaginationStyled>
          {!!conversationsTotalCount && (
            <Pagination
              page={page}
              onChange={onChangePage}
              count={calculatePagesCount(
                itemsPerPage,
                conversationsTotalCount || 1
              )}
            />
          )}
        </PaginationStyled>
      </ConversationListWrapperStyled>
    </ConversationListStyled>
  );
};

export default ClientLoginConversationList;
