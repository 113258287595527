import { useCallback } from 'react';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePostUserNotificationTokenMutation } from 'services/teamHeroApi.service';
import { checkIsNotificationData } from './NativePushNotificationsProvider.helper';

interface IUseNativePushNotificationsReturn {
  addEventListeners: () => Promise<void>;
  registerNotifications: () => Promise<void>;
  unregisterNotifications: () => Promise<void>;
}

export const useNativePushNotifications =
  (): IUseNativePushNotificationsReturn => {
    const user = useCurrentUser();
    const navigate = useNavigate();
    const isNativePlatform = Capacitor.isNativePlatform();

    const [postUserNotificationToken] = usePostUserNotificationTokenMutation();

    const addEventListeners = useCallback(async () => {
      if (!isNativePlatform || !user.isLoggedIn) {
        return;
      }
      await PushNotifications.addListener('registration', (token: Token) => {
        // we need to update the token for the user here
        postUserNotificationToken({
          body: {
            // FCM token on android, APNS token on iOS
            token: token.value,
          },
        });
      });

      // Some issue with our setup and push will not work
      await PushNotifications.addListener(
        'registrationError',
        (error: unknown) => {
          // eslint-disable-next-line no-console
          console.error('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          // eslint-disable-next-line no-console
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          const data = notification.notification?.data;
          if (data && checkIsNotificationData(data)) {
            navigate(data.route);
          }
          // eslint-disable-next-line no-console
          console.log('Push action performed: ' + JSON.stringify(notification));
        }
      );
    }, [
      isNativePlatform,
      navigate,
      postUserNotificationToken,
      user.isLoggedIn,
    ]);

    const registerNotifications = useCallback(async () => {
      if (!isNativePlatform || !user.isLoggedIn) {
        return;
      }

      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        // push notifications are not enabled on the device
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    }, [isNativePlatform, user.isLoggedIn]);

    const unregisterNotifications = useCallback(async () => {
      await PushNotifications.unregister();
    }, []);

    return {
      addEventListeners,
      registerNotifications,
      unregisterNotifications,
    };
  };
