import { FC } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router';
import { AppBar, BadgeCounter } from 'team-hero-ui';

import type { IDesktopNavigationLinkProps } from './DesktopNavigationLink.types';
import { RedDotStyled } from '../common/NavigationLink.styled';

const DesktopNavigationLink: FC<IDesktopNavigationLinkProps> = ({
  children,
  to,
  disabled = false,
  showRedDot = false,
  badgeCount = 0,
  dataTestId,
  onClick,
  label,
  matchEntirePath = false,
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: matchEntirePath });
  const component = onClick ? undefined : Link;
  const componentProps = onClick ? undefined : { to };
  return (
    <AppBar.Item
      disabled={disabled}
      onClick={onClick}
      active={!!match}
      dataTestId={dataTestId}
      label={label}
      component={component}
      componentProps={componentProps}
    >
      {children}
      {showRedDot && <RedDotStyled />}
      {badgeCount !== 0 && <BadgeCounter count={badgeCount} />}
    </AppBar.Item>
  );
};

export default DesktopNavigationLink;
