import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import moment, { Moment } from 'moment';

import type { ITimelineRangeSettingsState } from 'components/Timeline/interfaces/timelineRangeSettingState.interface';
import type { TTimelineSize } from 'components/Timeline/interfaces/timelineResolutions.interface';
import {
  calculateIncrementResolution,
  calculateTimeLineEnd,
} from 'components/Timeline/helper/timelineSettings.helper';

type TPickerSelectionType = 'start' | 'end';

interface IUseTimelineControlsReturn {
  handleOnClickLeftArrow: (pickerType: TPickerSelectionType) => void;
  handleOnClickRightArrow: (pickerType: TPickerSelectionType) => void;
  handleOnClickToday: () => void;
  handleDateChange: (
    date: Moment | null,
    pickerType: TPickerSelectionType
  ) => void;
  handleSetTimelineSize: (newTimelineSizeValue: TTimelineSize) => void;
}

interface IUseTimelineControlsProps<T> {
  setTimelineSettings: Dispatch<SetStateAction<T>>;
  minDayDifference?: number;
  maxDayDifference?: number;
}

const useTimelineRangeControls = <T extends ITimelineRangeSettingsState>({
  setTimelineSettings,
  minDayDifference = 0,
  maxDayDifference = 5000,
}: IUseTimelineControlsProps<T>): IUseTimelineControlsReturn => {
  // Corrects the date difference if the end date is before the start date
  const correctDateOverlap = useCallback(
    (currentState: T, pickerType: TPickerSelectionType) => {
      if (currentState.endDate.isBefore(currentState.startDate)) {
        if (pickerType === 'start') {
          currentState.endDate = currentState.startDate.clone().endOf('day');
        } else {
          currentState.startDate = currentState.endDate.clone().startOf('day');
        }
      }
    },
    []
  );

  const correctDateMinMaxDifference = useCallback(
    (currentState: T, pickerType: TPickerSelectionType): void => {
      // check if the difference between the start and end date is greater than the maxDayDateDifference
      if (
        currentState.endDate.diff(currentState.startDate, 'days') >
        maxDayDifference
      ) {
        // if the pickerType is start, set the end date to the maxDayDateDifference
        if (pickerType === 'start') {
          currentState.endDate = currentState.startDate
            .clone()
            .add(maxDayDifference, 'days')
            .endOf('day');
        } else {
          currentState.startDate = currentState.endDate
            .clone()
            .subtract(maxDayDifference, 'days')
            .startOf('day');
        }
      }

      // check if the difference between the start and end date is less than the minDayDateDifference
      if (
        currentState.endDate.diff(currentState.startDate, 'days') <
        minDayDifference
      ) {
        // if the pickerType is start, set the end date to the minDayDateDifference
        if (pickerType === 'start') {
          currentState.endDate = currentState.startDate
            .clone()
            .add(minDayDifference, 'days')
            .endOf('day');
        } else {
          currentState.startDate = currentState.endDate
            .clone()
            .subtract(minDayDifference, 'days')
            .startOf('day');
        }
      }
    },
    [maxDayDifference, minDayDifference]
  );

  // correct the date difference if maxDayDateDifference is changed
  useEffect(() => {
    setTimelineSettings((prevState) => {
      const currentState = { ...prevState };

      correctDateMinMaxDifference(currentState, 'start');

      return currentState;
    });
  }, [correctDateMinMaxDifference, maxDayDifference, setTimelineSettings]);

  const handleDateChange = useCallback(
    (date: Moment | null, pickerType: TPickerSelectionType): void => {
      setTimelineSettings((prevState) => {
        const currentState = { ...prevState };

        if (pickerType === 'start') {
          currentState.startDate = moment(date).clone().startOf('day');
        } else {
          currentState.endDate = moment(date).clone().endOf('day');
        }

        correctDateOverlap(currentState, pickerType);
        correctDateMinMaxDifference(currentState, pickerType);

        return currentState;
      });
    },
    [correctDateMinMaxDifference, correctDateOverlap, setTimelineSettings]
  );

  const handleOnClickLeftArrow = useCallback(
    (pickerType: TPickerSelectionType): void => {
      setTimelineSettings((prevState) => {
        const currentState = { ...prevState };

        if (pickerType === 'start') {
          currentState.startDate
            .subtract(
              1,
              calculateIncrementResolution(currentState.timelineSize)
            )
            .startOf('day');
        } else {
          currentState.endDate
            .subtract(
              1,
              calculateIncrementResolution(currentState.timelineSize)
            )
            .endOf('day');
        }

        correctDateOverlap(currentState, pickerType);
        correctDateMinMaxDifference(currentState, pickerType);

        return currentState;
      });
    },
    [correctDateMinMaxDifference, correctDateOverlap, setTimelineSettings]
  );

  const handleOnClickRightArrow = useCallback(
    (pickerType: TPickerSelectionType): void => {
      setTimelineSettings((prevState) => {
        const currentState = { ...prevState };

        if (pickerType === 'start') {
          currentState.startDate
            .add(1, calculateIncrementResolution(currentState.timelineSize))
            .startOf('day');
        } else {
          currentState.endDate
            .add(1, calculateIncrementResolution(currentState.timelineSize))
            .endOf('day');
        }

        correctDateOverlap(currentState, pickerType);
        correctDateMinMaxDifference(currentState, pickerType);

        return currentState;
      });
    },
    [correctDateMinMaxDifference, correctDateOverlap, setTimelineSettings]
  );

  const handleOnClickToday = useCallback((): void => {
    setTimelineSettings((prevState) => {
      const startDate = moment().startOf('day');
      return {
        ...prevState,
        startDate,
        endDate: calculateTimeLineEnd(prevState.timelineSize, startDate),
      };
    });
  }, [setTimelineSettings]);

  const handleSetTimelineSize = useCallback(
    (newTimelineSizeValue: TTimelineSize): void => {
      setTimelineSettings((prevState) => {
        const currentState = {
          ...prevState,
          timelineSize: newTimelineSizeValue,
        };

        const endDate = calculateTimeLineEnd(
          newTimelineSizeValue,
          prevState.startDate
        );

        currentState.endDate = endDate.endOf('day');

        return currentState;
      });
    },
    [setTimelineSettings]
  );

  return {
    handleOnClickLeftArrow,
    handleOnClickRightArrow,
    handleSetTimelineSize,
    handleOnClickToday,
    handleDateChange,
  };
};

export default useTimelineRangeControls;
