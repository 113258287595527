import { FC, ReactElement } from 'react';
import { BrowserRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { createUiKitTheme } from 'team-hero-ui';

import GlobalStyle from 'assets/styles/GlobalStyles';
import { store } from 'store';

interface IProvidersProps {
  children: ReactElement;
}

const theme = createUiKitTheme();

const Providers: FC<IProvidersProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BrowserRouter>{children}</BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default Providers;
