import { FC } from 'react';

import { useDateFormat } from 'hooks/useDateFormat.hook';
import { CommonCellContentStyled } from 'components/cellRenderers/Common.styled';
import { dateService } from 'services/date/date.service';

interface ICustomTimeCellRendererProps {
  start: string;
  end: string;
}

export const TimeCellRenderer: FC<ICustomTimeCellRendererProps> = ({
  start,
  end,
}) => {
  const { formatDate } = useDateFormat();
  const startTime = dateService(start).isValid()
    ? formatDate(start, 'time')
    : '-';
  const endTime = dateService(end).isValid() ? formatDate(end, 'time') : '-';
  return (
    <CommonCellContentStyled>
      {startTime} - {endTime}
    </CommonCellContentStyled>
  );
};
