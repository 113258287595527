import { FC, useCallback } from 'react';
import { Moment } from 'moment';
import { IconButton, Icons, Theme } from 'team-hero-ui';

import type { IFilterRendererProps } from 'components/FilterPanel/FilterPanel.type';
import LocalizedDatePicker from 'components/LocalizedDatePicker';
import { FilterLeftElementStyled } from 'components/FilterPanel/filterRenderers/FilterElement.styled';
import { parseDate } from '../helpers/date.helper';

export const DateTimePickerRenderer: FC<IFilterRendererProps> = ({
  label,
  onChange,
  value,
  styleType,
}) => {
  const handleClearValue = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const handleChangeValue = useCallback(
    (date: Moment | null) => {
      onChange(parseDate(date));
    },
    [onChange]
  );

  return (
    <FilterLeftElementStyled>
      <LocalizedDatePicker
        value={value}
        onChange={handleChangeValue}
        placeholder={label}
        label={label}
        type={styleType === 'modal' ? 'datetimeModal' : 'datetime'}
      />
      <IconButton size='small' onClick={handleClearValue}>
        <Icons.CloseIcon
          svgSize={16}
          svgColor={
            styleType === 'modal'
              ? Theme.colors.primary.white
              : Theme.colors.primary.grey
          }
        />
      </IconButton>
    </FilterLeftElementStyled>
  );
};
