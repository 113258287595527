import { useCallback } from 'react';

import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { TruncatedCellStyled } from 'components/Table/components/TableBody/TableBody.styled';
import {
  StyledSummaryTableCell,
  StyledSummaryHeader,
} from './SummaryRow.styled';
import { getGridColsWidth } from 'components/Table/components/util';

type TColumnSummaryType<T> = IColumnDefinitionType<T>;

interface ISummaryRowProps<T> {
  row: T;
  columns: TColumnSummaryType<T>[];
}

export const SummaryRow = <T,>({ row, columns }: ISummaryRowProps<T>) => {
  const renderCell = useCallback(
    (column: TColumnSummaryType<T>) => {
      const getCell = () => {
        if (column.cellType === 'custom' && column.cellRenderer) {
          return column.cellRenderer(row, false);
        }

        return (
          <TruncatedCellStyled>
            <>{row[column.key as keyof T] || '-'}</>
          </TruncatedCellStyled>
        );
      };

      return (
        <StyledSummaryTableCell
          $isFirst={column.key === 'status'}
          key={`cell-1-${column.key}`}
          $disabled={false}
        >
          {getCell()}
        </StyledSummaryTableCell>
      );
    },

    [row]
  );

  const checkIfColumnShouldBeRendered = useCallback(
    (column: TColumnSummaryType<T>) => {
      return column.visibility;
    },
    []
  );

  return (
    <StyledSummaryHeader
      key={`inner-row-1`}
      $gridColsWidth={getGridColsWidth(columns)}
    >
      {columns.map((column) => {
        return checkIfColumnShouldBeRendered(column)
          ? renderCell(column)
          : null;
      })}
    </StyledSummaryHeader>
  );
};
