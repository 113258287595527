import type { IContact } from 'interfaces/Contact.interface';
import type { IUserMe } from 'interfaces/User.interface';
import {
  useGetContactItemQuery,
  useGetUserMeQuery,
} from 'services/teamHeroApi.service';

interface IUseCurrentUserContactReturn {
  isFetching: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  currentUserContact: IContact | undefined;
  currentUser: IUserMe | undefined;
}

const useCurrentUserContact = (
  skip = false,
  refetchOnMountOrArgChange = false
): IUseCurrentUserContactReturn => {
  const {
    data: currentUser,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    isError: isErrorUser,
  } = useGetUserMeQuery(null, { skip });

  const {
    data: currentUserContact,
    isLoading: isLoadingContact,
    isFetching: isFetchingContact,
    isSuccess: isSuccessContact,
    isError: isErrorContact,
  } = useGetContactItemQuery(
    {
      id: currentUser
        ? parseInt(
            (currentUser.contact || '').replace('/api/contacts/', ''),
            10
          )
        : 0,
    },
    {
      skip: !currentUser || skip || !currentUser.contact,
      refetchOnMountOrArgChange: refetchOnMountOrArgChange,
    }
  );

  return {
    isFetching: isFetchingUser || isFetchingContact,
    isLoading: isLoadingContact || isLoadingUser,
    currentUserContact,
    currentUser,
    isSuccess: isSuccessContact,
    isError: isErrorContact || isErrorUser,
  };
};

export default useCurrentUserContact;
