import { ReactElement } from 'react';

import { StatusStyled } from './StatusCellRenderer.styled';

export const StatusCellRenderer = (
  statusColor: string,
  onClick?: () => void,
  disabled?: boolean
): ReactElement => {
  return (
    <StatusStyled
      $disabled={disabled}
      onClick={onClick}
      $statusColor={statusColor}
    />
  );
};
