import { ReactElement, useEffect, useMemo } from 'react';
import { Button, Icons, Theme } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import type { ITableViewProps } from './TableView.types';
import type { IId } from 'interfaces/IId.interface';
import PaginationFooter from 'components/PaginationFooter';
import { ToolbarHeader } from 'components/ToolbarHeader';
import FilterRow from 'components/FilterRow';
import calculatePagesCount from 'components/PaginationFooter/helper/calculatePagesCount.helper';
import ItemsPerPageDropdownItems from 'components/PaginationFooter/helper/defaultItemsPerPageDropdownItems';
import useFilterComponent from 'components/FilterPanel/hooks/useFilterComponent.hook';
import Table from 'components/Table';
import { ColumnSelectionDropdown } from './addons/Header/components/ColumnSelectionDropdown';
import { useColumnList } from './hooks/useColumnList.hook';
import {
  BulkBottomStyled,
  BulkTopStyled,
  MainPanelStyled,
  TableBodyWrapperStyled,
  TableViewStyled,
  TableWrapperStyled,
} from './TableView.styled';
import { DownloadSectionDropdown } from './addons/Header/components/DownloadSectionDropdown';
import { useDownload } from './hooks/useDownload.hook';
import NoResultGraphic from 'components/NoResultGraphic';

export const TableView = <T extends IId>({
  data,
  columns,
  filterDefinitions,
  filterData,
  pagination,
  columnsOffset,
  rowIdMenuOpen,
  sorting,
  onAddClickLabel,
  headerLeftAdditional,
  headerRightAdditional,
  itemsTotalCount,
  styleType,
  headerCenterSection,
  bottomRowRenderer,
  topRowRenderer,
  isLoading = false,
  isFetching = false,
  disableHeader = false,
  disableColumnSelection = false,
  disableDownloadSection = false,
  filterTopSection,
  filterVisible = false,
  customDownloadSection,
  onCustomPdfDownload,
  onAddClick,
  editRowComponent,
  bulkEditRow,
  onRowClick,
  paginationSelector,
  setColumnListDispatch,
  rowCollapsibleContentRenderer,
  filtersDisplayType,
  itemsPerPageOptions,
  downloadOptions,
  disableTableHeaderRow = false,
}: ITableViewProps<T>): ReactElement => {
  const {
    menuColumnList,
    tableColumns,
    handleChange,
    handleOnAllClick,
    handleOnDragEnd,
  } = useColumnList({
    columns,
    columnsOffset,
    paginationSelector,
    setColumnListDispatch,
  });

  // const [widthTypeRef] = useCalculatedWidthType(data.length);

  const { t } = useTranslation('general');

  const { downloadXLS, isDownloading } = useDownload<T>(data, tableColumns, t);
  const {
    toggleFilterVisibility,
    FilterComponent,
    FilterChipsComponent,
    filtersAreSet,
  } = useFilterComponent({
    filterData,
    filterDefinitionList: filterDefinitions,
    type: filtersDisplayType,
    initialIsFilterVisible: filterVisible,
    filterTopSection,
  });

  useEffect(() => {
    if (itemsTotalCount > 0 && data.length === 0 && pagination) {
      pagination.onResetPage();
    }
  }, [data, itemsTotalCount, pagination]);

  const filtersEnabled =
    filterDefinitions && filterDefinitions.length > 0 && filterData;

  const headerRenderer = useMemo(
    () => (
      <ToolbarHeader
        leftSection={
          <>
            {filtersEnabled && (
              <Button
                color='toolbar'
                onClick={toggleFilterVisibility}
                startIcon={
                  <Icons.FilterIcon
                    svgColor={Theme.colors.greyShades.grey2}
                    svgSize={13}
                  />
                }
                data-test-id='open-filters-button'
              >
                {itemsTotalCount}
              </Button>
            )}
            {headerLeftAdditional}
          </>
        }
        centerSection={headerCenterSection}
        rightSection={
          <>
            {headerRightAdditional}
            {onAddClick && (
              <Button
                color='primary'
                startIcon={
                  <Icons.PlusIconV1
                    svgColor={Theme.colors.primary.white}
                    svgSize={18}
                  />
                }
                onClick={onAddClick}
                data-test-id='add-button'
              >
                {onAddClickLabel ? onAddClickLabel : t('table.header.add')}
              </Button>
            )}
            {customDownloadSection && customDownloadSection(tableColumns)}
            {!disableDownloadSection && (
              <DownloadSectionDropdown
                handleOnXLSDownload={downloadXLS}
                handleOnPDFDownload={onCustomPdfDownload}
                disabled={isFetching}
                isLoadingXLSDownload={isDownloading}
              >
                {downloadOptions}
              </DownloadSectionDropdown>
            )}
            {!disableColumnSelection && (
              <ColumnSelectionDropdown
                items={menuColumnList}
                handleChange={handleChange}
                handleOnAllClick={handleOnAllClick}
                onDragEnd={handleOnDragEnd}
              />
            )}
          </>
        }
        secondRow={
          filtersAreSet && <FilterRow>{FilterChipsComponent}</FilterRow>
        }
      />
    ),
    [
      FilterChipsComponent,
      customDownloadSection,
      disableColumnSelection,
      disableDownloadSection,
      downloadOptions,
      downloadXLS,
      filtersAreSet,
      filtersEnabled,
      handleChange,
      handleOnAllClick,
      handleOnDragEnd,
      headerCenterSection,
      headerLeftAdditional,
      headerRightAdditional,
      isFetching,
      itemsTotalCount,
      menuColumnList,
      onAddClick,
      onAddClickLabel,
      onCustomPdfDownload,
      t,
      tableColumns,
      toggleFilterVisibility,
      isDownloading,
    ]
  );

  const paginationRenderer = useMemo(() => {
    if (!pagination) {
      return null;
    }
    return (
      <PaginationFooter
        dropdownProps={{
          items: itemsPerPageOptions || ItemsPerPageDropdownItems,
          onChange: (value: number) => pagination.onSetItemsPerPage(value),
          selected: pagination.itemsPerPage,
        }}
        paginationProps={{
          styleType,
          count: calculatePagesCount(pagination.itemsPerPage, itemsTotalCount),
          page: pagination.page,
          onChange: pagination.onChangePage,
        }}
      />
    );
  }, [pagination, itemsPerPageOptions, itemsTotalCount, styleType]);

  return (
    <TableViewStyled>
      {FilterComponent}
      <MainPanelStyled
        $noHeader={disableHeader}
        data-component='table-main-panel'
      >
        {!disableHeader && headerRenderer}
        {!isLoading && data.length === 0 && <NoResultGraphic />}
        {(isLoading || !!data.length) && (
          <>
            <TableBodyWrapperStyled>
              <BulkTopStyled>{bulkEditRow('top')}</BulkTopStyled>
              <TableWrapperStyled>
                <Table
                  columns={tableColumns}
                  data={data}
                  rowIdMenuOpen={rowIdMenuOpen}
                  editRowComponent={editRowComponent}
                  rowCollapsibleContentRenderer={rowCollapsibleContentRenderer}
                  onRowClick={onRowClick}
                  isMin={false}
                  displayHeader={!disableTableHeaderRow}
                  sorting={sorting}
                  bottomRowRenderer={bottomRowRenderer}
                  topRowRenderer={topRowRenderer}
                  isLoading={isLoading}
                  numSkeletonRows={20}
                />
              </TableWrapperStyled>
              <BulkBottomStyled>{bulkEditRow('bottom')}</BulkBottomStyled>
            </TableBodyWrapperStyled>
            {paginationRenderer}
          </>
        )}
      </MainPanelStyled>
    </TableViewStyled>
  );
};
