import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ICustomField,
  ICustomFields,
} from 'interfaces/CustomFields.interface';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { getCustomFieldReadableValue } from './getCustomFieldReadableValue';

interface ICustomFieldValueRendererProps {
  field?: ICustomField;
  unformattedValue: unknown;
  customFields?: ICustomFields;
}

export const CustomFieldValue: FC<ICustomFieldValueRendererProps> = ({
  field,
  unformattedValue,
  customFields,
}) => {
  const { t, i18n } = useTranslation('general');
  const { formatDate } = useDateFormat();

  if (!field || unformattedValue === undefined || unformattedValue === null) {
    return <>-</>;
  }

  const value = getCustomFieldReadableValue({
    formatDate,
    language: i18n.languages[0],
    t,
    unformattedValue,
    field,
    customFields,
  });

  return <>{value}</>;
};
