import { styled } from 'styled-components';
import { Theme, Utils, device } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface IDateWrapperProps {
  $hasAddress?: boolean;
}

export const AddressCityStyled = styled.p`
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
  font-weight: 700;
`;

export const CardContentStyled = styled.div`
  padding ${pxToRem([0, 12])};
`;

export const DateWrapper = styled.div<IDateWrapperProps>`
  width: 100%;
  height: auto;
  ${flexFn('space-between', 'center')};
  gap: ${pxToRem(24)};
  margin: ${({ $hasAddress }) => [$hasAddress ? 0 : -24, 0, 24, 0]};
`;

export const DateInnerWrapper = styled.div`
  width: unset;
  height: auto;
  ${flexFn('flex-start')};
  gap: ${pxToRem(24)};
  margin: 0;
`;

export const JobsHeaderStyled = styled.div`
  color: ${Theme.colors.primary.grey};
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
  margin-bottom: ${pxToRem(12)};
  span {
    font-size: ${pxToRem(24)};
  }
  p {
    font-size: ${pxToRem(15)};
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const ShiftsCountStyled = styled.div`
  ${flexFn('flex-start', 'stretch')};
  gap: ${pxToRem(8)};
`;

export const ShiftCountValueStyled = styled.span`
  font-size: ${pxToRem(14)};
  color: ${Theme.colors.greyShades.grey2};
`;

export const ShiftCountStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(4)};
`;

export const GreyThinText = styled.span`
  color: ${Theme.colors.greyShades.grey3};
  font-weight: 300;
  line-height: ${pxToRem(16)};
  font-size: ${pxToRem(12)};

  @media ${device.md} {
    line-height: ${pxToRem(18)};
    font-size: ${pxToRem(14)};
  }
`;

export const DefaultText = styled.span`
  color: ${Theme.colors.primary.grey};
  line-height: ${pxToRem(16)};
  font-size: 12px;

  @media ${device.sm} {
    line-height: ${pxToRem(18)};
    font-size: ${pxToRem(14)};
  }
`;
