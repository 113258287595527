import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const WrapperStyled = styled.div`
  position: relative;
  ${flexFn('flex-start', 'center', 'column')};
  gap: ${pxToRem(5)};
  width: 100%;
  max-width: ${pxToRem(40)};
`;
