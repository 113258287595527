import { useCallback } from 'react';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

interface IUseNativeAppUpdateReturn {
  triggerFetchVersionInfo: () => Promise<void>;
}

/**
 * @description This hook is used to handle native app updates
 * @usage const { triggerFetchVersionInfo } = useNativeAppUpdate();
 */
const useNativeAppUpdate = (): IUseNativeAppUpdateReturn => {
  const { t } = useTranslation();

  /**
   * this is only available on android
   */
  const performImmediateUpdate = useCallback(async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (
      result.immediateUpdateAllowed &&
      Capacitor.getPlatform() === 'android'
    ) {
      await AppUpdate.performImmediateUpdate();
    }
  }, []);

  /**
   * trigger the fetch of the version info
   * this will check if an update is available
   * on android, it will perform an immediate update
   * on ios, it will show an alert to the user
   * on web, it will do nothing
   */
  const triggerFetchVersionInfo = useCallback(async () => {
    // if not native platform, do nothing
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    const result = await AppUpdate.getAppUpdateInfo({
      country: 'de',
    });
    // on android, we can perform an immediate update
    if (Capacitor.getPlatform() === 'android') {
      if (
        result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        performImmediateUpdate();
      }
    } else {
      // on ios, we need show an alert to the user
      if (
        result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        alert(t('appUpdate.iosAvailableUpdate'));
      }
    }
  }, [performImmediateUpdate, t]);

  return {
    triggerFetchVersionInfo,
  };
};

export default useNativeAppUpdate;
