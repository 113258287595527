import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type {
  ISelectContactValue,
  ISelectValue,
} from 'interfaces/Data/SelectValue.interface';
import type { TExternalDataValues } from './externalDataValues.types';
import {
  useGetAbsenceCategoriesQuery,
  useGetCategoriesQuery,
} from 'services/teamHeroApi.service';
import mapResultByType from './mapResultByType.helper';
import { useGetPublicApplicationJobAdsQuery } from 'services/publicTeamHeroApi.service';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import getFiltersByType from 'hooks/getSelectExternalOptionValues/getFiltersByType.helper';

export const useGetSelectExternalOptionValues = (
  externalDataType?: TExternalDataValues,
  skip = false,
  additionalFilters: ISimpleFilter[] = []
): { data: ISelectValue[] & ISelectContactValue[]; isLoading: boolean } => {
  const filters = getFiltersByType(externalDataType);
  const scalarFieldsFilter: ISimpleFilter = {
    key: 'scalarFieldsOnly',
    operator: 'AND',
    value: true,
  };

  const { data: absenceCategoryData, isLoading: isAbsenceCategoryLoading } =
    useGetAbsenceCategoriesQuery(
      {},
      {
        skip:
          skip || !externalDataType || externalDataType !== 'absenceCategory',
      }
    );

  const { data: applicationJobAdsData, isLoading: isApplicationJobAdsLoading } =
    useGetPublicApplicationJobAdsQuery(
      {
        pagination: false,
        filters: [scalarFieldsFilter, ...additionalFilters],
      },
      { skip: skip || externalDataType !== 'jobAd' }
    );

  const { data: categoryData, isLoading: isCategoryLoading } =
    useGetCategoriesQuery(
      {
        filters: [...additionalFilters, ...filters],
        ...createPaginationApiArgs(false),
      },
      { skip: skip || externalDataType !== 'category' }
    );

  const externalValuesResult = [
    absenceCategoryData,
    applicationJobAdsData,
    categoryData,
  ].find((externalDataItem) => !!externalDataItem);

  const filterValuesResult = mapResultByType(
    externalDataType,
    externalValuesResult
  );

  return {
    data: filterValuesResult,
    isLoading:
      isAbsenceCategoryLoading ||
      isApplicationJobAdsLoading ||
      isCategoryLoading,
  };
};
