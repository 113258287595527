import type { TFeatureFlags } from 'components/FeatureFlag/featureFlag.type';
import { useGetPublicSystemSettingsQuery } from 'services/publicTeamHeroApi.service';

interface IUseFlagsReturn {
  isFeatureFlagEnabled: (flag: TFeatureFlags) => boolean;
  isLoading: boolean;
}

/**
 * useFlags hook
 * @description A hook that returns the feature flags
 */
export const useFlags = (): IUseFlagsReturn => {
  const { data: systemSettingsData, isLoading } =
    useGetPublicSystemSettingsQuery();

  const flags: Record<TFeatureFlags, boolean> = {
    clientPool: false,
    clientRatings: false,
    clientDashboardRatingsCard: false,
    clientDashboardApplicationsCard: false,
    clientDocuments: true,
    '2factorAuth': systemSettingsData?.settings?.two_factor_enabled || false,
  };

  const isFeatureFlagEnabled = (flag: TFeatureFlags): boolean => {
    return flags[flag];
  };

  return { isFeatureFlagEnabled, isLoading };
};
