import { ReactElement, Suspense, useEffect } from 'react';
import { Loader } from 'team-hero-ui';

import Routes from 'routes';
import { useCurrentUser } from 'hooks/useCurrentUser';
import GlobalComponentsProvider from 'components/GlobalComponentsProvider';

const App = (): ReactElement => {
  const user = useCurrentUser();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).userEmail = user.email || '';
  }, [user.email]);

  return (
    <>
      <Suspense fallback={<Loader loaderType='fixed' loaderSize='big' />}>
        <Routes />
      </Suspense>
      <GlobalComponentsProvider />
    </>
  );
};

export default App;
