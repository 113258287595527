import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDatePickerProps,
  DatePicker,
  AdapterMoment,
  LocalizationProvider,
} from 'team-hero-ui';
import moment, { Moment } from 'moment';

import type { TLocale } from 'interfaces/Locale.interface';

interface ILocalizedDatePickerProps extends IDatePickerProps<Moment> {
  helperText?: string;
  format?: string;
}

const LocalizedDatePicker: FC<ILocalizedDatePickerProps> = (props) => {
  const { i18n } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.languages[0]}
      dateLibInstance={moment}
    >
      <DatePicker
        {...props}
        value={props.value}
        locale={i18n.languages[0] as TLocale}
      />
    </LocalizationProvider>
  );
};

export default LocalizedDatePicker;
