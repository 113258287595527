import { isNumber } from 'lodash';
import countries from 'i18n-iso-countries';
import { TFunction } from 'i18next';

import type { TFormatDateFunction } from 'hooks/useDateFormat.hook';
import type {
  ICustomField,
  ICustomFields,
} from 'interfaces/CustomFields.interface';
import type { TLocale } from 'interfaces/Locale.interface';
import { customFieldsService } from 'services/customFields/customFields.service';

interface IGetCustomFieldReadableValueArgs {
  field: ICustomField;
  unformattedValue: unknown;
  customFields?: ICustomFields;
  language: string;
  formatDate: TFormatDateFunction;
  t: TFunction;
}

export const getCustomFieldReadableValue = ({
  unformattedValue,
  customFields,
  field,
  language,
  formatDate,
  t,
}: IGetCustomFieldReadableValueArgs): string => {
  let value = '';

  switch (field.renderType) {
    case 'switch':
      value = unformattedValue ? t('common.yes') : t('common.no');
      break;
    case 'number':
    case 'float':
      if (isNumber(unformattedValue)) {
        value = unformattedValue.toString();
      } else {
        value = (unformattedValue || '-') as string;
      }
      break;
    case 'selectCountry':
      value =
        unformattedValue && countries.isValid(unformattedValue as string)
          ? countries.getName(unformattedValue as string, language, {
              select: 'official',
            }) || '-'
          : '-';
      break;
    case 'controlledDate':
      break;
    case 'date':
      value = unformattedValue
        ? formatDate(unformattedValue as string, 'default')
        : '-';
      break;
    case 'year':
      value = unformattedValue
        ? formatDate(unformattedValue as string, 'year')
        : '-';
      break;
    case 'time':
      value = unformattedValue
        ? formatDate(unformattedValue as string, 'time')
        : '-';
      break;
    case 'password':
      value = unformattedValue ? '********' : '-';
      break;
    case 'select':
    case 'selectInput':
      if (field.values) {
        const options = customFieldsService({
          customFields,
          language: language as TLocale,
        }).mapValuesToOptions(field.values);

        const selectOption = options.find(
          (option) => option.value === unformattedValue
        );

        value = selectOption ? selectOption.label : '-';
      } else {
        value = (unformattedValue || '-') as string;
      }
      break;
    case 'empty':
      value = '-';
      break;
    case 'image':
    case 'colorSelect':
    case 'images':
    case 'file':
    case 'textarea':
    case 'currency':
    case 'source':
    case 'multiselect':
    case 'multiselectContact':
    case 'contactSelect':
    case 'draftArea':
    case 'text':
    default:
      value = (unformattedValue || '-') as string;
  }
  return value;
};
