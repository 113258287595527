import { FC } from 'react';
import { ConversationHeader, Icons, Loader } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { IClientLoginConversationPanelProps } from './ClientLoginConversationPanel.types';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import { useGetMessageConversationsQuery } from 'services/teamHeroApi.service';
import {
  ConversationListStyled,
  ConversationPanelStyled,
  KeyAccountStyled,
  NewConversationActionCardStyled,
  PhoneNumberStyled,
} from './ClientLoginConversationPanel.styled';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import ClientLoginConversationList from '../ClientLoginConversationList/ClientLoginConversationList';
import { paginatedConversationsSelector } from 'redux/collections/conversations/conversations.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DashboardActionCard from '../DashboardActionCard';
import Avatar from 'components/Avatar';
import { setMessagesDetails } from 'redux/messages/messages.slice';

const ClientLoginConversationPanel: FC<IClientLoginConversationPanelProps> = ({
  leader,
  isLoadingLeader,
  conversationsFilter,
  conversationType,
  mission,
  height = '100dvh',
}) => {
  const { t } = useTranslation('general');

  const dispatch = useAppDispatch();

  const { currentUserContact } = useCurrentUserContact();

  const { pagination } = useAppSelector(paginatedConversationsSelector);

  const {
    data: conversationsData,
    isLoading: isLoadingConversations,
    isError,
  } = useGetMessageConversationsQuery(
    {
      participant: currentUserContact?.id,
      ...createPaginationApiArgs(pagination, {
        columnKey: 'lastMessage.createdAt',
        direction: 'desc',
      }),
      filters: conversationsFilter,
    },
    { skip: !currentUserContact?.id }
  );

  if (isError) {
    throw new Error('Error fetching conversations');
  }

  const navigate = useNavigate();

  const onStartConversationClick = () => {
    if (leader) {
      dispatch(
        setMessagesDetails({
          mission,
          isContactDetailsModalOpen: false,
        })
      );
      navigate(`/messages/new-conversation`);
    } else {
      navigate(`/messages/new-message`);
    }
  };

  const isLoading = isLoadingConversations || isLoadingLeader;

  const hasConversations = !isLoading && !!conversationsData?.items?.length;

  const conversationsHeader = () => {
    return (
      <ConversationHeader
        leftSideElements={
          <>
            <Avatar
              sourceType='file'
              size={'L'}
              images={leader?.avatar || ''}
              firstName={leader?.firstName}
              lastName={leader?.lastName}
            />
            <KeyAccountStyled>
              <p>
                {leader?.firstName} {leader?.lastName}
              </p>
              <p>
                {leader
                  ? t(`details.${conversationType}`)
                  : t('details.noKeyAccount')}
              </p>
            </KeyAccountStyled>
          </>
        }
        rightSideElements={
          <>
            {leader?.phone && (
              <PhoneNumberStyled>
                <Icons.TelephoneIcon svgSize={20} />
                <p>{leader.phone}</p>
              </PhoneNumberStyled>
            )}
          </>
        }
      />
    );
  };

  // show list of conversations or create new conversation panel
  return (
    <ConversationPanelStyled $height={height}>
      {isLoading ? (
        <Loader loaderSize={'small'} loaderType={'static'} />
      ) : hasConversations ? (
        <ConversationListStyled>
          {conversationsHeader()}
          <ClientLoginConversationList
            conversations={conversationsData.items}
            conversationsTotalCount={conversationsData.totalCount}
          />
        </ConversationListStyled>
      ) : (
        <NewConversationActionCardStyled>
          <DashboardActionCard
            type={`${conversationType}Conversation`}
            icon={<Icons.MessageUniversalIcon svgSize={100} />}
            onActionButtonClick={onStartConversationClick}
          />
        </NewConversationActionCardStyled>
      )}
    </ConversationPanelStyled>
  );
};

export default ClientLoginConversationPanel;
