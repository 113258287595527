import { FC } from 'react';

import type { IContactEmbed } from 'interfaces/Contact.interface';
import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';
import Avatar from 'components/Avatar';
import { LeaderCellContainerStyled } from './LeaderCellRenderer.styled';

interface ILeaderCellRendererProps {
  embeddedContact?: IContactEmbed;
}

export const LeaderCellRenderer: FC<ILeaderCellRendererProps> = ({
  embeddedContact,
}) => {
  return (
    <LeaderCellContainerStyled>
      {embeddedContact ? (
        <Avatar
          sourceType='file'
          images={embeddedContact.avatar || ''}
          embeddedContact={embeddedContact}
          thumbnailSize='small'
        />
      ) : (
        <EmptyCell contentText='' />
      )}
    </LeaderCellContainerStyled>
  );
};
