import { FC } from 'react';
import { Theme } from 'team-hero-ui';

import { TextCellRendererStyled } from './TextCellRenderer.styled';

interface ITextCellRendererProps {
  text?: string | number;
  leftTab?: number;
  color?: string | null;
  displayEmpty?: boolean;
  isBold?: boolean;
  onClick?: () => void;
}

export const TextCellRenderer: FC<ITextCellRendererProps> = ({
  text,
  leftTab,
  color = Theme.colors.primary.grey,
  displayEmpty = false,
  isBold = false,
  onClick,
}) => {
  const emptyValue = displayEmpty ? '' : '-';

  return (
    <TextCellRendererStyled
      $leftTab={leftTab}
      $color={color}
      $isBold={isBold}
      onClick={onClick}
    >
      {text || emptyValue}
    </TextCellRendererStyled>
  );
};
