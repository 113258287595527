import { FC, useCallback, useMemo } from 'react';
import { MultiSelectFreeFlow } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import { IMultiSelectRendererProps } from 'components/FilterPanel/FilterPanel.type';

const defaultArray: [] = [];

export const MultiSelectFreeFlowRenderer: FC<IMultiSelectRendererProps> = ({
  label,
  staticValues,
  value = defaultArray,
  onChange,
  styleType,
}) => {
  const { t } = useTranslation('general');
  const selectLabel = t('selectValue', { filterLabel: label });

  const selectOptions = useMemo((): string[] => {
    return staticValues?.map((staticValue) => staticValue.value) || [];
  }, [staticValues]);

  const onChangeHandler = useCallback(
    (updatedItems: string[]) => {
      onChange(updatedItems);
    },
    [onChange]
  );

  return (
    <MultiSelectFreeFlow
      width={'100%'}
      inputLabel={selectLabel}
      selectedItems={value}
      options={selectOptions}
      handleChange={onChangeHandler}
      styleType={styleType === 'modal' ? styleType : 'filter'}
    />
  );
};
