import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IShiftApplication } from 'interfaces/ShiftApplication.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IShiftApplicationPostArgs,
  IGetShiftApplicationsResponse,
  IGetShiftApplicationsCollectionArgs,
  IShiftApplicationDeleteArgs,
  IShiftApplicationUpdateArgs,
  IShiftApplicationOptimisticUpdateArgs,
} from './shiftApplications.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { teamHeroApi } from 'services/teamHeroApi.service';

const defaultTotalCount = 0;
const defaultItems: IShiftApplication[] = [];

export const getShiftApplications = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetShiftApplicationsCollectionArgs,
  BaseQueryFn,
  string,
  IGetShiftApplicationsResponse
> =>
  build.query<
    IGetShiftApplicationsResponse,
    IGetShiftApplicationsCollectionArgs
  >({
    providesTags: ['ShiftApplication'],
    query: (queryArg: IGetShiftApplicationsCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/project_shift_applications${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IShiftApplication>
    ): IGetShiftApplicationsResponse => {
      const totalCount =
        (response['hydra:totalItems'] as number) || defaultTotalCount;
      const items =
        (response['hydra:member'] as IShiftApplication[]) || defaultItems;
      return { items, totalCount };
    },
  });

export const postApplicationShiftItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IShiftApplicationPostArgs,
  BaseQueryFn,
  string,
  IShiftApplication
> =>
  build.mutation<IShiftApplication, IShiftApplicationPostArgs>({
    invalidatesTags: ['ShiftApplication'],
    query: (queryArg) => ({
      url: `/api/project_shift_applications`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const deleteApplicationShiftItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IShiftApplicationDeleteArgs,
  BaseQueryFn,
  string,
  IShiftApplication
> =>
  build.mutation<IShiftApplication, IShiftApplicationDeleteArgs>({
    invalidatesTags: ['ShiftApplication'],
    query: (queryArg) => ({
      url: `/api/project_shift_applications/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const putApplicationShiftItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IShiftApplicationUpdateArgs,
  BaseQueryFn,
  string,
  IShiftApplication
> =>
  build.mutation<IShiftApplication, IShiftApplicationUpdateArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ShiftApplication'],
    query: (queryArg) => ({
      url: `/api/project_shift_applications/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const putOptimisticApplicationShiftItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IShiftApplicationOptimisticUpdateArgs,
  BaseQueryFn,
  string,
  IShiftApplication
> =>
  build.mutation<IShiftApplication, IShiftApplicationOptimisticUpdateArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ShiftApplication'],
    query: (queryArg) => ({
      url: `/api/project_shift_applications/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
    async onQueryStarted(
      { id, missionId, ...patch },
      { dispatch, queryFulfilled }
    ) {
      const patchResult = dispatch(
        teamHeroApi.util.updateQueryData(
          'getShiftApplications',
          {
            filters: [
              { key: 'shift.mission', operator: 'AND', value: missionId },
            ],
            pagination: false,
          },
          (draft) => {
            const item = draft.items.find((el) => el.id === id);
            if (item) {
              const sameContactApplications = draft.items.filter(
                (el) => el.contact.id === item.contact.id
              );

              // change clientStatus for all applications with same contact
              sameContactApplications.forEach((el) => {
                el.clientStatus = patch.body.clientStatus;
              });
            }
          }
        )
      );
      try {
        await queryFulfilled;
      } catch (_e) {
        patchResult.undo();
      }
    },
  });
