import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { retry } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import type { ITokenResponse } from '../user/user.types';
import { store } from 'store';
import { logoutByForce } from 'redux/user/user.thunk';
import {
  getRefreshToken,
  getToken,
  removeRefreshToken,
  removeToken,
  storeRefreshToken,
  storeToken,
} from '../user/user.helper';

const mutex = new Mutex();

export const customBaseQueryFn = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URI,
  prepareHeaders: (headers: Headers) => {
    // token in localStorage :(
    const token = getToken();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const noAuthCustomBaseQueryFn = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URI,
});

const resetUser = async () => {
  removeRefreshToken();
  removeToken();
  return store.dispatch(logoutByForce());
};

/**
 * interceptor that tries to reauthenticate, if token is no longer valid
 */
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // if mutex is locked, wait for it to unlock before making the new request
  await mutex.waitForUnlock();
  let result = await customBaseQueryFn(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // check if mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      // check if refresh token is set
      try {
        const refreshToken = getRefreshToken();
        // if refresh token is set, fetch new token with refresh token
        if (refreshToken) {
          const refreshResult = await customBaseQueryFn(
            {
              url: 'api/token/refresh',
              body: {
                refresh_token: refreshToken,
              },
              method: 'POST',
            },
            api,
            extraOptions
          );
          if (refreshResult.data) {
            const {
              token: tokenReceived,
              refresh_token: refreshTokenReceived,
            } = refreshResult.data as ITokenResponse;
            storeToken(tokenReceived);
            storeRefreshToken(refreshTokenReceived);
          } else {
            // handle refreshTokenFetchError: Log out user ?? and remove tokens ?? redirect to login page
            await resetUser();
            // stop retrying
            retry.fail(result.error);
          }
        } else {
          await resetUser();
          // stop retrying
          retry.fail(result.error);
        }
      } finally {
        // we need to release the mutex, so other requests can be made
        release();
      }
    }
    // if mutex is locked, wait for it to unlock and try again
    await mutex.waitForUnlock();
    result = await customBaseQueryFn(args, api, extraOptions);
  }
  return result;
};
